import React, { useState } from 'react';


function PropertyDetailsDesc(props) {
    const [showMore, setShowMore] = React.useState(false);
    const charLimit = 300;
    const truncatedDesc =
        props.description.length > charLimit
            ? props.description.substring(0, charLimit) + "..."
            : props.description;
    const toggleShowMore = () => setShowMore(!showMore);

    if (props.description.length === 0) return null;

    return (
        <div className="property-info__section">
            <h2 className="property-details__heading heading-5">
                Property details
            </h2>
            <div className="property-details__description">
                <p>
                    {showMore ? props.description : truncatedDesc}
                    {props.description.length > charLimit && (
                        <a href="#"
                        className={`show-more-link ${showMore ? "show-less" : "show-more"}`}
                        onClick={(event) => { event.preventDefault(); toggleShowMore(); }}>
                            {showMore ? "Show less" : "Show more"}
                        </a>
                    )}
                </p>
            </div>
        </div>
    );
}



export default PropertyDetailsDesc
