import { useContext, useState } from "react";
import {
  FilterContext,
  FilterDispatchContext,
} from "../../Context/FilterContext";

const PROPERTY_TYPES = [
  { value: "all", label: "All" },
  { value: "house", label: "House" },
  { value: "condominium", label: "Condominium" },
  { value: "residential_lot", label: "Residential Lot" },
  { value: "commercial_lot", label: "Commercial Lot" },
  { value: "commercial_building", label: "Commercial Building" },
  { value: "office", label: "Office" },
  { value: "industrial", label: "Industrial" },
  { value: "agricultural", label: "Agricultural" },
  { value: "beach", label: "Beach" },
];

export function PropertyType({ onFilterChange }) {
  const filters = useContext(FilterContext);
  const currentPropertyType = filters?.propertyType || "all";
  const [activeButton, setActiveButton] = useState(currentPropertyType); // Set the initial state to the current filter value

  const handleChange = (selectedOption) => {
    const value = selectedOption.value;

    onFilterChange("propertyType", value); // Update the local filter state in the parent component

    setActiveButton(value);
  };

  return (
    <>
      {PROPERTY_TYPES.map((type) => (
        <button
          className={`${activeButton === type.value ? "active" : ""} ${
            type.value
          }`}
          key={type.value}
          onClick={() => handleChange(type)}
        >
          <span className="icon"></span>
          {type.label}
        </button>
      ))}
    </>
  );
}
