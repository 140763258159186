import React from 'react';

function ListingPrice({ transaction_type, sale_price, lease_price }) {
  let formatted_price = '';

  if (transaction_type == 'any') {
    // Both prices exist, handle this case appropriately
    formatted_price = sale_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '') + ' / Lease Php' + lease_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '') ;
  } else if (transaction_type == 'sale' ) {
    // Sale price exists, format it
    formatted_price = sale_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '');
  } else if (transaction_type == 'lease' ) {
    // Lease price exists, format it
    formatted_price = lease_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00', '');
  } else {
    // Neither price exists, handle this case appropriately
  }

  return (
    <>
      Php {formatted_price}
    </>
  );
}

export default ListingPrice;
