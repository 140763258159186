import React from 'react';

function PropertyDetailsFeatures(props) {
    if (props.collections.length === 0) return null;

    return (
        <div className="property-info__section">
            <div className="property-info_features">
                <ul>
                    {
                        props.collections.map((collection, index) =>
                            <li key={index}>
                                <img src={"/Assets/icons/categories/" + collection.slug + ".svg"} alt={collection.name} />
                                {collection.name}
                            </li>
                        )
                    }
                </ul>
            </div>
        </div >
    )
}

export default PropertyDetailsFeatures
