import React, { useState, useContext } from "react";
import { FilterDispatchContext, FilterContext } from "../Context/FilterContext";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

import { TransactionType } from "./FilterModal/TransactionType";
import { Location } from "./FilterModal/Location";
import { PropertyType } from "./FilterModal/PropertyType";
import { PriceRange } from "./FilterModal/PriceRange-v1";

const GalleryModalstyle = styled.section`
  position: fixed;
  overflow: hidden;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 300ms;
  padding: 2%;

  @keyframes moveUp {
    0% {
      transform: translateY(150px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .filter-modal-inner {
    animation: moveUp 300ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    max-width: 800px;
    background-color: #fff;
    border-radius: 16px;
    margin: 0 auto;
    max-height: 100vh;
    z-index: 9999999;
    position: relative;
    /* margin-top: 0;

    @media screen and (min-width: 768px) {
      margin-top: 60px;
    } */
  }

  .modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    left: auto;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .modal-header {
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid var(--primary-grey-100);
  }
  .filters-container {
    padding: 0 8px 0 0;

    .filters-inner {
      overflow-y: auto;
      max-height: 70vh;
      padding: 24px;
    }

    .filter-actions {
      //margin: 24px 0;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid var(--primary-grey-100);
      padding-top: 24px;
      padding: 24px;

      button {
        border: none;
        background: transparent;
        font-size: 20px;
        line-height: 32px;
        cursor: pointer;

        &.clear-filter {
          color: #7e6947;
          font-size: 16px;
        }

        &.apply-filter {
          background: #ddb367;
          padding: 8px 24px;
          color: #fff;
          border-radius: 100px;
          transition: linear all 300ms;

          &:hover {
            background: var(--color-secondary-500);
          }
        }
      }
    }

    .filter-set {
      margin: 24px 0;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--primary-grey-100);
      max-width: 100%;

      @media screen and (min-width: 768px) {
        max-width: 95%;
      }

      &:last-child {
        border: none;
        margin-bottom: 0;
      }

      .filter-items {
        margin-top: 16px;
        display: flex;
        gap: 16px;
        flex: 1;
        overflow: auto;
        scroll-snap-type: x mandatory;
        -webkit-scrollbar-width: none;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;

        &::-webkit-scrollbar {
          display: none;
        }

        @media screen and (min-width: 768px) {
          display: grid;
          grid-template-columns: repeat(5, 1fr); /* 5 items per row */
        }

        span.icon {
          display: block;
          width: 16px;
          height: 20px;
          position: relative;
          margin-bottom: 8px;

          &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            mask-position: center center;
            mask-repeat: no-repeat;
            background-size: contain;
            mask-size: contain;
            background-color: var(--primary-grey-300);
          }
        }

        button {
          border: 1px solid var(--primary-grey-200);
          border-radius: 8px;
          background: #fff;
          padding: 12px;
          text-align: left;
          font-size: 12px;
          cursor: pointer;
          transition: all linear 300ms;
          width: auto;
          flex: 0 1 auto;
          min-width: 110px;

          &.active {
            color: var(--color-secondary-500);
            background: var(--primary-100);
            border: 1px solid var(--primary-grey-500);

            /* span.icon::before {
              background-color: var(--color-secondary-500);
            } */
          }
          &.active .icon::before {
            background-color: var(--color-secondary-500);
          }
        }
      }
    }

    h3 {
      color: var(--primary-grey-500);
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: var(--primary-grey-500) #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--primary-grey-100);
      border-radius: 10px;
      border: 3px none #ffffff;
    }
  }

  .set-transaction-type {
    .sale-lease .icon::before,
    .all .icon::before {
      -webkit-mask-image: url("../../Assets/search/ico-all.svg");
      mask-image: url("../../Assets/search/ico-all.svg");
    }
    .sale .icon::before {
      -webkit-mask-image: url("../../Assets/search/ico-sale.svg");
      mask-image: url("../../Assets/search/ico-sale.svg");
    }
    .lease .icon::before {
      -webkit-mask-image: url("../../Assets/search/ico-lease.svg");
      mask-image: url("../../Assets/search/ico-lease.svg");
    }
  }

  .set-property-type {
    .all .icon::before {
      -webkit-mask-image: url("../../Assets/search/ico-all.svg");
      mask-image: url("../../Assets/search/ico-all.svg");
    }
    .house .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/house.svg");
      mask-image: url("../../Assets/search/property-types/house.svg");
    }
    .commercial_building .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/commercial.svg");
      mask-image: url("../../Assets/search/property-types/commercial.svg");
    }
    .residential_lot .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/residential-lot.svg");
      mask-image: url("../../Assets/search/property-types/residential-lot.svg");
    }
    .commercial_lot .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/commercial-lot.svg");
      mask-image: url("../../Assets/search/property-types/commercial-lot.svg");
    }
    .condominium .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/condominium.svg");
      mask-image: url("../../Assets/search/property-types/condominium.svg");
    }
    .industrial .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/industrial.svg");
      mask-image: url("../../Assets/search/property-types/industrial.svg");
    }
    .office .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/office.svg");
      mask-image: url("../../Assets/search/property-types/office.svg");
    }
    .lot .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/lot.svg");
      mask-image: url("../../Assets/search/property-types/lot.svg");
    }
    .agricultural .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/agricultural.svg");
      mask-image: url("../../Assets/search/property-types/agricultural.svg");
    }
    .beach .icon::before {
      -webkit-mask-image: url("../../Assets/search/property-types/resort.svg");
      mask-image: url("../../Assets/search/property-types/resort.svg");
    }
  }
`;

export const FilterModal = (props) => {
  // Introduce a local state to store filter values until user decides to apply
  const [localFilters, setLocalFilters] = useState({});
  const dispatch = useContext(FilterDispatchContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleApplyFilters = () => {
    // Dispatch the local filters to the global context when "Apply filters" is clicked
    const urlSearchParams = new URLSearchParams();

    for (let key in localFilters) {
      const value = localFilters[key];
      let isDelete = typeof value === "string" && value.toLowerCase() === "all";

      dispatch({
        action: isDelete ? "delete" : "set",
        filter: {
          key: key,
          value: value,
        },
      });

      // Update the URL with the new filter
      if (value && !isDelete) {
        urlSearchParams.set(key, value);
      } else {
        urlSearchParams.delete(key);
      }
    }

    // Prefix the query string with `q=1`
    let prefixedQueryString = `q=1&${urlSearchParams.toString()}`;

    // Check if the current page is not home
    if (location.pathname !== "/") {
      navigate(`/?${prefixedQueryString}`);
    } else {
      const newRelativePathQuery = `${window.location.pathname}?${prefixedQueryString}`;
      window.history.pushState(null, "", newRelativePathQuery);
    }
  };

  const handleApplyAndClose = () => {
    props.setTrigger(false); // Close the modal
    handleApplyFilters(); // Then apply filters
  };

  return props.trigger ? (
    <GalleryModalstyle>
      <div className="filter-modal-inner">
        <button className="modal-close" onClick={() => props.setTrigger(false)}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 4.11074L16.3893 2.5L10 8.88926L3.61074 2.5L2 4.11074L8.38926 10.5L2 16.8893L3.61074 18.5L10 12.1107L16.3893 18.5L18 16.8893L11.6107 10.5L18 4.11074Z"
              fill="#5E676F"
            />
          </svg>
        </button>
        <div className="modal-header">
          <h2 className="heading-5">Refine your search</h2>
        </div>
        <div className="filters-container set-transaction-type">
          <div className="filters-inner">
            <div className="filter-set">
              <h3 className="heading-6">Looking for:</h3>
              <div className="filter-items">
                <TransactionType
                  onFilterChange={(key, value) =>
                    setLocalFilters((prev) => ({ ...prev, [key]: value }))
                  }
                />
              </div>
            </div>

            <div className="filter-set set-property-type">
              <h3 className="heading-6">Property type</h3>
              <div className="filter-items">
                <PropertyType
                  onFilterChange={(key, value) =>
                    setLocalFilters((prev) => ({ ...prev, [key]: value }))
                  }
                />
              </div>
            </div>

            <div className="filter-set">
              <h3 className="heading-6">Price Range</h3>
              <PriceRange
                onFilterChange={(key, value) =>
                  setLocalFilters((prev) => ({ ...prev, [key]: value }))
                }
              />
            </div>

            <div className="filter-set">
              <h3 className="heading-6">Location</h3>
              <div className="filter-items">
                <Location
                  onFilterChange={(key, value) =>
                    setLocalFilters((prev) => ({ ...prev, [key]: value }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="filter-actions">
            <button className="clear-filter">Clear filters</button>
            <button className="apply-filter" onClick={handleApplyAndClose}>
              Apply filters
            </button>
          </div>
        </div>
      </div>
    </GalleryModalstyle>
  ) : (
    ""
  );
};

export default FilterModal;
