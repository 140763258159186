import { useContext, useState, useEffect } from "react";
import { FilterContext, FilterDispatchContext } from "../Context/FilterContext";
import { SearchIcon } from "../Util/SVGIcons";

export function SearchByKeyword() {
  const dispatch = useContext(FilterDispatchContext);
  const filterState = useContext(FilterContext); // Access the filter state

  const [searchKeyword, setSearchKeyword] = useState(""); // State to track the input value

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    // Dispatch the searchKeyword filter value
    dispatch({
      action: "set",
      filter: {
        key: "searchKeyword",
        value: searchKeyword,
      },
    });

    // Check if it's not the homepage and redirect to the homepage
    if (window.location.pathname !== "/") {
      const newURL = `/?q=1&keyword=${encodeURIComponent(searchKeyword)}`;
      window.location.href = newURL;
    } else {
      // If it's the homepage, update the URL parameters without a redirect
      const newURL = `/?q=1&keyword=${encodeURIComponent(searchKeyword)}`;
      window.history.pushState({}, "", newURL);
    }
  };

  const clearFilters = () => {
    dispatch({
      action: "clear",
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasSearchParam = urlParams.get("keyword") !== null;

    if (hasSearchParam) {
      const searchValue = urlParams.get("keyword");
      setSearchKeyword(searchValue);
    }

    if (!hasSearchParam) {
      // Reset the filter context
      setSearchKeyword("");
      clearFilters();
    }
  }, []);

  //Listen for changes in the filterState
  useEffect(() => {
    if (filterState && typeof filterState.searchKeyword === "undefined") {
      // Check if filterState exists and if searchKeyword is missing or empty
      setSearchKeyword(""); // Reset the local state
    }
  }, [filterState]);

  return (
    <div className="search-bar__keyword">
      <form onSubmit={handleSearchSubmit}>
        {" "}
        {/* Use onSubmit handler */}
        <input
          type="text"
          placeholder="Keyword search"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)} // Update the search input state
        />
        <div className="search-bar-submit">
          <button type="submit">
            <SearchIcon fill={"#fff"} height={20} width={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
