import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

//import InfiniteScroll from "react-infinite-scroll-component";
import { FilterContext } from "./Context/FilterContext";
import NoResultsFound from "./NoResultsFound";
import PropertyCard from "./PropertyCard";

const PropertiesGridstyle = styled.section`
  background-position: bottom center;
  margin-bottom: 42px;

  &.sticky {
    padding-top: 380px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .properties-card-container {
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (min-width: 1440px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  .preloader {
    width: 100%;
    display: block;
    img {
      display: block;
      margin: 30px auto;
    }
  }
  .load-more-container {
    padding: 30px 0;
    .container {
      display: block;
      text-align: center;
      color: var(--color-secondary);

      button {
        display: block;
        margin: 16px auto;
        max-width: 200px;
        //border: 1px solid var(--color-secondary);
        background-color: var(--color-primary);
        border-radius: 100px;
        border: none;
        padding: 16px 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;

const getProperties = (filters, limit, cb) => {
  const url = `${process.env.REACT_APP_API_URL}/properties/`;
  const params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    let apiKey;
    switch (key) {
      case "transactionType":
        apiKey = "listing__transaction_type__in";
        break;
      case "min_price":
        apiKey = "listing__sale_price__gte";
        break;
      case "max_price":
        apiKey = "listing__sale_price__lte";
        break;
      case "propertyType":
        apiKey = "type";
        break;
      case "collection":
        apiKey = "collections__slug__in";
        break;
      case "location":
        apiKey = "city__contains";
        break;
      case "searchKeyword":
        apiKey = "search";
        break;
      default:
        apiKey = key;
    }

    if (filters[key] !== "" && filters[key] !== undefined) {
      params.append(apiKey, filters[key]);
    }
  });

  params.append("ordering", "-listing__sale_price");
  //  params.append("limit", limit);
  params.append("limit", "1000"); // set limit to 0 for unlimited results

  axios
    .get(url, { params })
    .then((res) => cb(res.data.results, res.data.count, null))
    .catch((err) => cb(null, null, err));
};

const PropertiesGrid = ({ activeClass }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [shownListings, setShownListings] = useState(16); // initially show 12 properties
  const filters = useContext(FilterContext);
  const [totalProperties, setTotalProperties] = useState(0);

  const fetchMoreData = useCallback((currentFilters, currentListings) => {
    setIsLoading(true);
    const params = { ...currentFilters, offset: currentListings.length };
    const limit = 0; // add the limit value here
    getProperties(params, limit, (data, totalCount, err) => {
      if (err) {
        console.error(err);
      } else {
        setListings([...currentListings, ...data]);
        setTotalProperties(totalCount);
        setIsLoading(true);
        console.log("is fetching more");
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchMoreData(filters, []);
  }, [filters, fetchMoreData]);

  const handleLoadMore = () => {
    setShownListings(shownListings + 20); // increase by 16 on each load
  };

  const displayedListings = listings.slice(0, shownListings);

  return (
    <>
      <PropertiesGridstyle className={activeClass}>
        {isLoading && (
          <div className="container">
            <div className="properties-card-container">
              <SkeletonTheme baseColor="#ebebeb" highlightColor="#D3D3D3">
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
                <Skeleton height={356} />
              </SkeletonTheme>
            </div>
          </div>
        )}
        <div className="container">
          <div className="properties-card-container">
            {displayedListings.map((listing, idx) => (
              <PropertyCard listing={listing} key={idx} />
            ))}
          </div>
        </div>

        {!isLoading && listings.length === 0 && (
          <NoResultsFound count={listings.length} />
        )}
        {!isLoading && listings.length > displayedListings.length && (
          <div className="load-more-container">
            <div className="container">
              {displayedListings.length} of {totalProperties} properties
              <button onClick={handleLoadMore}>Load More</button>
            </div>
          </div>
        )}
        {!isLoading && displayedListings.length === listings.length && (
          <div className="load-more-container">
            <div className="container">
              <p>
                {" "}
                {displayedListings.length} of {totalProperties} properties
              </p>
              You have reached the end
            </div>
          </div>
        )}
      </PropertiesGridstyle>
    </>
  );
};

export default PropertiesGrid;
