import styled from "styled-components";

export const PropertyDetailsstyle = styled.section`
  .inner__search-bar {
    padding: 28px 0;
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: 98;
    box-shadow: var(--elevation-4dp);
  }

  .search-bar__inner {
    display: block;
    padding-top: 80px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      min-height: 140px;
    }
  }
  .property-detail__container {
    min-height: 500px;
    background-color: var(--background-grey-90);
    margin-top: 42px;

    @media screen and (min-width: 992px) {
      margin-top: 0;
    }

    .property-detail__breadcrumbs,
    .property-detail__title,
    .property-detail__sub-heading {
      margin: 24px 0;

      @media screen and (min-width: 992px) {
        margin: 32px 0;
      }

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .property-detail__title h1 br {
    display: none;

    @media screen and (min-width: 992px) {
      display: block;
    }
  }
  .property-detail__sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .property-detail__actions,
  .property-detail__pin {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .property-detail__icon {
    display: flex;
    align-items: center;
    color: var(--color-secondary-300);
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      fill: var(--color-secondary-300);
      margin-right: 10px;
    }
  }
  .property-details__location-score {
    margin-top: 24px;
  }
  .property-detail__details {
    margin-top: 0;
    display: block;
    grid-template-columns: 7fr 5fr;
    position: relative;
    grid-gap: 24px;
    overflow: hidden;

    @media screen and (min-width: 992px) {
      display: grid;
      margin-top: 34px;
    }

    .property-details__info {
      .property-details__status-type,
      .property-details__price,
      .property-details__title {
        margin-bottom: 16px;
      }

      .property-info__section {
        padding: 16px 0;
        border-bottom: 1px solid var(--primary-grey-200);

        @media screen and (min-width: 768px) {
          padding: 40px 0;
        }

        .property-details__heading {
          margin-bottom: 24px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .property-info_structure {
        ul {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;

          @media only screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          li {
            display: flex;
            align-items: center;
            font-family: var(--font-primary);
            font-size: 18px;
            line-height: 32px;
            font-weight: 700;
            color: var(--primary-grey-400);

            @media only screen and (min-width: 768px) {
              font-size: 24px;
            }

            svg {
              margin-right: 15px;
              max-width: 38px;
            }
          }
        }
      }

      .property-info_features {
        ul {
          display: block;

          @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;
          }

          li {
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0 0 16px 0;

            @media screen and (min-width: 768px) {
              margin: 0 0 16px 0;
            }

            svg,
            img {
              //margin-right: 16px;
              filter: brightness(0) contrast(0.3);
              max-width: 36px;
            }
          }
        }
      }

      .property-details__status-type {
        font-size: 12px;
        font-family: var(--font-primary);
        text-transform: capitalize;

        .badge {
          text-transform: uppercase;
          margin-right: 8px;

          a {
            color: #fff;
          }
        }
      }
      .property-details__price {
        color: var(--color-accent);
      }
      .property-details__description {
        white-space: pre-wrap;

        a {
          display: block;
          margin: 16px 0;
          font-weight: 400;
        }

        .show-more-link {
          position: relative;
          padding-right: 16px;

          &:after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-size: contain;
            top: 8px;
            margin-left: 10px;
            transition: all linear 200ms;
          }
        }
        .show-more:after {
          background-image: url("../Assets/chevron.svg");
        }
        .show-less:after {
          background-image: url("../Assets/chevron.svg");
          transform: rotate(180deg);
          top: 3px;
        }
      }
    }
  }
`;

export const SuggestedPropertiesstyle = styled.section`
  margin-top: 24px;
  background-color: var(--background-grey-100);
  padding: 100px 0;

  h2 {
    margin-bottom: 32px;
  }
`;

export const SlideInRight = styled.div`
  //transform: translateY(100%);
  //transition: transform 0.2s ease-in-out;
  //visibility: hidden;
  //height: 0;
  //position: fixed;
  display: none;

  @media (min-width: 992px) {
    //transform: translateY(0);
    //visibility: visible;
    display: block;
    //position: sticky;
    //top: 220px;
  }

  &.active {
    //transform: translateY(0);
    //visibility: visible;
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    width: 100%;
    max-height: 100vh;
    margin: 0 -16px;
    //overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
