import React, { useContext } from "react";
import { FilterDispatchContext, FilterContext } from "../Context/FilterContext";
import styled from "styled-components";

const ActiveFilterstyle = styled.section`
  //max-width: 500px;
  margin: 0 auto;
  padding: 16px;

  .active-filters {
    display: flex;
    gap: 8px;
  }

  .filter-item {
    background-color: #fff;
    border-radius: 16px;
    font-size: 12px;
    padding: 4px 0 4px 8px;
    text-transform: capitalize;
    display: flex;
    box-shadow: var(--elevation-1dp);

    button {
      border: none;
      background: transparent;
      color: var(--color-secondary);
      cursor: pointer;
    }
  }
  .clear-filter {
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    align-items: center;
  }
`;

const ActiveFilters = () => {
  const filters = useContext(FilterContext) || {};

  const {
    transactionType,
    propertyType,
    location,
    collection,
    searchKeyword,
    price,
  } = filters;

  const dispatch = useContext(FilterDispatchContext);

  const clearFilters = () => {
    dispatch({
      action: "clear",
    });
    // Clear URL parameters by replacing the current URL without query parameters
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const handleRemoveFilter = (key) => {
    // 1. Dispatch the deletion to the context
    if (key === "min_price" && filters.max_price) {
      dispatch({
        action: "delete",
        filter: { key: "max_price" },
      });

      // Remove max_price from the URL
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("max_price");
    }

    dispatch({
      action: "delete",
      filter: { key: key },
    });

    // 2. Remove the filter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(key);

    // Update the URL without causing a page refresh
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
  };

  const formatNumber = (num) => {
    // If the number is one million or more
    if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert it to a format like 1.5M
    }
    return num.toLocaleString("en-US");
  };

  const displayValue = (key, value) => {
    if (key === "min_price" && filters.max_price) {
      return `₱ ${formatNumber(value)} - ${formatNumber(filters.max_price)}`;
    }
    if (key === "max_price" && filters.min_price) {
      return null; // We don't want to display max_price separately when min_price exists
    }
    if (key === "min_price" || key === "max_price") {
      return `₱ ${formatNumber(value)}`;
    }

    if (typeof value === "string") {
      // Replace all underscores with spaces
      value = value.replace(/_/g, " ");
      return value.replace(/-/g, " ");
    }

    return value;
  };

  // Check if filters is empty
  if (Object.keys(filters).length === 0) {
    return null;
  }
  console.log("Filters:", filters);

  const isDefaultFilters =
    (!transactionType || transactionType === "all") &&
    (!propertyType || propertyType === "all") &&
    (!location || location === "all") &&
    (!collection || collection === "") &&
    (!searchKeyword || searchKeyword === "") &&
    (!price || price === "all");

  return (
    <ActiveFilterstyle>
      <div className="active-filters">
        {Object.keys(filters).map((filterKey) => {
          const value = displayValue(filterKey, filters[filterKey]);

          if (!value) return null; // Skip rendering this filter item if no value

          return (
            <div className="filter-item" key={filterKey}>
              <span>{value}</span>
              <button onClick={() => handleRemoveFilter(filterKey)}>✖</button>
            </div>
          );
        })}

        {!isDefaultFilters && (
          <a href="#" className="clear-filter" onClick={clearFilters}>
            Clear Filters
          </a>
        )}
      </div>
    </ActiveFilterstyle>
  );
};

export default ActiveFilters;
