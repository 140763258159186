import React from "react";

export function openFormPopupContactUs() {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLScr_mdaYyQTyupt-RKCRfKZcaXA3G4qPvjc2WcU0ZsO-nTb5w/viewform?embedded=true",
    "formPopup",
    "width=600,height=800"
  );
}

export function openFormPopupListWithUs() {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSdYLA04s7-ukv41l_mw9MbaQxmX2LFtHGn2Nw54eVc_NEBOIw/viewform",
    "formPopup",
    "width=600,height=800"
  );
}
//export default ListingPrice;
