import React, { Component, useState, useEffect } from "react";
import SiteLogo from "../Assets/logo.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MobileNav from "./MobileNav";

import {
  openFormPopupContactUs,
  openFormPopupListWithUs,
} from "./Util/PopupForms";
import {
  FBIcon,
  HamburgerIcon,
  HomeIcon,
  InstagramIcon,
  YoutubeIcon,
} from "./Util/SVGIcons";

const Headerstyle = styled.header`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all linear 200ms;

  &.scrolled {
    background-color: rgba(255, 255, 255, 1);
  }

  .main-nav {
    font-family: var(--font-primary);

    ul {
      gap: 10px;
      display: flex;
      gap: 30px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .socials {
    ul {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }

  .main-nav,
  .socials {
    display: none;

    .ico-navHome {
      svg {
        margin-top: 3px;
      }
    }
  }
  .header-mobile-nav {
    display: block;

    button {
      background-color: transparent;
      border: none;
    }
  }
  @media screen and (min-width: 992px) {
    .header-mobile-nav {
      display: none;
    }
    .main-nav,
    .socials {
      display: block;
    }
  }
`;

function Header(props) {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("sidebar-open", buttonPopup);
  }, [buttonPopup]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 300;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <Headerstyle className={scrolled ? "scrolled" : ""}>
      <div className="container">
        <div className="logo">
          <Link to={`/`}>
            <img src={SiteLogo} alt="Propeller PH" />
          </Link>
        </div>
        <div className="header-mobile-nav">
          <button onClick={() => setButtonPopup(true)}>
            <HamburgerIcon />
          </button>
        </div>
        <div className="main-nav">
          <ul className="main-nav_top-level">
            <li className="ico-navHome">
              <Link to={`/`} title="Home">
                <HomeIcon />
              </Link>
            </li>
            <li>
              <Link to={"/about-us"}>About</Link>
            </li>
            <li>
              <a href="#" onClick={openFormPopupListWithUs} rel="noreferrer">
                List with us
              </a>
            </li>
            <li>
              <Link to={"/services"}>Services</Link>
            </li>
            <li>
              <a href="#" onClick={openFormPopupContactUs} rel="noreferrer">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="socials">
          <ul>
            <li className="ico-social ico-fb">
              <a
                href="https://www.facebook.com/Propeller.Realty/"
                target="_blank"
                rel="noreferrer"
              >
                <FBIcon />
              </a>
            </li>
            <li className="ico-social ico-ig">
              <a
                href="https://instagram.com/propeller.realty?igshid=OGQ2MjdiOTE="
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
            {/* <li className="ico-social ico-tw">
                            <a href="#">
                                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1498 3.51837C16.1612 3.67277 16.1612 3.82721 16.1612 3.98162C16.1612 8.69115 12.4493 14.1176 5.66499 14.1176C3.57488 14.1176 1.63326 13.5331 0 12.5184C0.296966 12.5515 0.582471 12.5625 0.890863 12.5625C2.61546 12.5625 4.20305 12 5.47083 11.0405C3.84899 11.0074 2.48985 9.98163 2.02156 8.56985C2.25001 8.60292 2.47842 8.62498 2.71829 8.62498C3.0495 8.62498 3.38074 8.58085 3.6891 8.50368C1.99875 8.17277 0.730936 6.73896 0.730936 5.00735V4.96325C1.22204 5.22795 1.79315 5.39339 2.39844 5.41542C1.40478 4.77571 0.753788 3.68381 0.753788 2.4485C0.753788 1.78675 0.936496 1.18013 1.25631 0.650719C3.07232 2.81248 5.80203 4.22422 8.86293 4.37866C8.80583 4.11395 8.77155 3.83825 8.77155 3.5625C8.77155 1.59925 10.4162 0 12.4607 0C13.5228 0 14.4822 0.430146 15.1561 1.125C15.9898 0.970595 16.7893 0.672786 17.4975 0.264708C17.2233 1.09193 16.6409 1.78679 15.8756 2.22793C16.618 2.15076 17.3376 1.95219 18 1.67648C17.4975 2.38234 16.8693 3.01099 16.1498 3.51837Z" fill="#7E6947" />
                                </svg>
                            </a>
                        </li> */}
            <li className="ico-social ico-yt">
              <a
                href="https://www.youtube.com/@PropellerRealty"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeIcon />
              </a>
            </li>
            {/* <li className="ico-social ico-in">
                            <a href="#">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.93414 17.5006H0.290314V5.81699H3.93414V17.5006ZM2.11027 4.22324C0.945089 4.22324 0 3.2623 0 2.10215C8.33985e-09 1.54488 0.222331 1.01044 0.618083 0.616396C1.01384 0.222349 1.55059 0.000976562 2.11027 0.000976562C2.66995 0.000976562 3.2067 0.222349 3.60245 0.616396C3.9982 1.01044 4.22054 1.54488 4.22054 2.10215C4.22054 3.2623 3.27505 4.22324 2.11027 4.22324ZM17.5718 17.5006H13.9358V11.8131C13.9358 10.4576 13.9084 8.71934 12.0414 8.71934C10.1469 8.71934 9.85655 10.192 9.85655 11.7154V17.5006H6.21664V5.81699H9.71139V7.41074H9.76239C10.2489 6.49277 11.4372 5.52402 13.2101 5.52402C16.8978 5.52402 17.5758 7.94199 17.5758 11.0826V17.5006H17.5718Z" fill="#7E6947" />
                                </svg>
                            </a>
                        </li> */}
          </ul>
        </div>
      </div>
      <MobileNav trigger={buttonPopup} setTrigger={setButtonPopup}></MobileNav>
    </Headerstyle>
  );
}

export default Header;
