import styled from "styled-components";

export const HeroFilter = styled.div`
  background-position: bottom center;
  display: block;
  gap: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  /* @media only screen and (min-width: 992px) {
    display: block;
  } */

  .search-bar {
    background-color: var(--color-white);
    border-radius: 50px;
    justify-content: center;
    box-shadow: var(--elevation-1dp);
    transition: all linear 300ms;
    display: flex;
    overflow: hidden;

    .search-bar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 0 16px;

      &:not(:nth-last-of-type(2)):after {
        position: absolute;
        content: "";
        height: 80%;
        width: 1px;
        background-color: var(--primary-grey-100);
        right: 0;
      }

      label,
      input {
        display: block;
      }

      label {
        font-size: 12px;
        font-family: var(--font-primary);
      }

      input {
        border: none;
        width: 100%;
        outline: none;
        font-size: 14px;
        &::placeholder {
          color: var(--color-secondary-200);
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -webkit-appearance: none !important;
          -moz-appearance: textfield;
        }
      }
    }

    .search-bar-submit {
      button {
        border-radius: 100%;
        background-color: var(--color-primary);
        border: none;
        margin: 0;
        cursor: pointer;
        width: 48px;
        height: 48px;
      }
    }
  }

  .button-filter {
    flex: 1;
    border-right: 1px solid var(--primary-grey-100);

    button {
      height: 100%;
      background: transparent;
      border: 0;
      cursor: pointer;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-secondary-500);
    }

    &.active button {
      background-color: var(--color-secondary-500);
      color: #fff;
    }
  }

  .search-bar__keyword {
    flex: 1;
    padding: 4px;
    width: 100%;

    form {
      display: flex;
      align-items: center;
      height: 100%;

      input {
        outline: none;
        border: none;
        min-width: 150px;
        max-width: 150px;
        font-size: 16px;
        padding: 8px 16px;
        color: var(--primary-grey-200);

        @media screen and (min-width: 768px) {
          min-width: 250px;
          max-width: 250px;
        }
      }
    }
  }

  .filter-btn {
    margin: 16px auto 0 auto;

    @media screen and (min-width: 768px) {
      margin: 0;
    }

    button {
      background: #fff;
      border: 1px solid var(--primary-grey-100);
      padding: 8px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #323a41;
      font-size: 16px;
      cursor: pointer;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        padding: 16px;
      }
    }
  }

  .search-by-keyword {
    margin: 16px 0;
    text-align: center;

    span a {
      color: var(--color-primary);
      text-decoration: none;
      transition: all linear 200ms;

      &:hover {
        color: var(--color-secondary);
        text-decoration: underline;
      }
    }
  }

  .btn-clear-filter {
    background-color: transparent;
    border: none;
    display: block;
    margin: 10px auto;
    cursor: pointer;
    color: var(--color-secondary);
    text-decoration: none;
    transition: all linear 200ms;

    &:hover {
      text-decoration: underline;
    }
  }
`;
