import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  CloseIcon,
  FBIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
  LinkedInIcon,
} from "./Util/SVGIcons";

import {
  openFormPopupContactUs,
  openFormPopupListWithUs,
} from "./Util/PopupForms";

const MobileNavstyle = styled.div`
  //display: block;
  top: 0;
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-white);
  transition: 200ms;
  animation: moveUp 200ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  @keyframes moveUp {
    0% {
      transform: translateX(150px);
    }
    100% {
      transform: translateX(0);
    }

    /* @media screen and (min-width: 992px) {
        display: none;
    } */
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
  }
  .btn-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 24px;

    button {
      background-color: transparent;
      border: none;
    }
  }
  .mobile-nav {
    width: 100%;

    font-family: var(--font-primary);

    ul {
      display: block;

      li {
        a {
          padding: 36px 16px 24px 16px;
          display: block;
          border-bottom: 1px solid var(--primary-grey-100);
        }

        a.current,
        a.active {
          font-weight: 500;
          border-bottom: 2px solid var(--color-secondary);
        }
      }
    }
  }

  .mobile-nav-socials {
    width: 100%;
    justify-self: end;
    margin-top: auto;

    ul {
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: space-evenly;
    }
  }
`;

function MobileNav(props) {
  const [buttonPopup, setButtonPopup] = useState(false);

  const handleLinkClick = (e, callback) => {
    if (callback) {
      callback(e);
    }
    props.setTrigger(false);
  };

  return props.trigger ? (
    <MobileNavstyle>
      <div className="container">
        <div className="btn-close">
          <button onClick={() => props.setTrigger(false)}>
            <CloseIcon
              width={24}
              height={24}
              fill={"var(--primary-grey-300)"}
            />
          </button>
        </div>
        <div className="mobile-nav">
          <ul className="main-nav_top-level">
            <li>
              <NavLink
                to={"/"}
                activeClassName="current"
                onClick={(e) => handleLinkClick(e)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/about-us"}
                activeClassName="current"
                onClick={(e) => handleLinkClick(e)}
              >
                About
              </NavLink>
            </li>
            <li>
              <a href="#" onClick={openFormPopupListWithUs} rel="noreferrer">
                List with us
              </a>
            </li>
            <li>
              <NavLink to={"/services"} onClick={(e) => handleLinkClick(e)}>
                Services
              </NavLink>
            </li>
            <li>
              <a href="#" onClick={openFormPopupContactUs} rel="noreferrer">
                Contact
              </a>
            </li>
            {/* <li>
              <a href="#" onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)} >Blog</a>
            </li>
            <li>
              <a href="#" onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)} >
                My Items <span className="notif-items"></span>
              </a>
            </li> */}
          </ul>
        </div>

        <div className="mobile-nav-socials">
          <ul>
            <li className="ico-social ico-fb">
              <a
                href="#"
                onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)}
              >
                <FBIcon />
              </a>
            </li>
            <li className="ico-social ico-ig">
              <a
                href="#"
                onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)}
              >
                <InstagramIcon />
              </a>
            </li>
            <li className="ico-social ico-tw">
              <a
                href="#"
                onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)}
              >
                <TwitterIcon />
              </a>
            </li>
            <li className="ico-social ico-yt">
              <a
                href="#"
                onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)}
              >
                <YoutubeIcon />
              </a>
            </li>
            <li className="ico-social ico-in">
              <a
                href="#"
                onClick={(e) => handleLinkClick(e, openFormPopupListWithUs)}
              >
                <LinkedInIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </MobileNavstyle>
  ) : (
    ""
  );
}

export default MobileNav;
