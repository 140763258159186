import React from "react";
import styled from "styled-components";

const LocationScorestyle = styled.section`
  background-color: var(--background-grey-100);
  border-radius: 8px;
  border: 1px solid var(--background-grey-200);
  padding: 16px;
  position: relative;
  margin-bottom: 16px;

  h3 {
    color: var(--color-secondary-500);
    margin-bottom: 16px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-black-500);
  }
  p {
    color: var(--color-black-300);
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .stars-container {
    position: absolute;
    right: 16px;
    top: 16px;

    ul {
      margin: 0;
      list-style: none;
      display: flex;
      gap: 4px;
    }
  }
`;

class Score {
  constructor(stars, title, description) {
    this.stars = stars;
    this.title = title;
    this.description = description;
  }
}

const ALL_SCORES = {
  walk: [
    new Score(1, "Car-Dependent", "Almost all errands require a car"),
    new Score(2, "Car-Dependent", "Most errands require a car"),
    new Score(
      3,
      "Somewhat Walkable",
      "Some errands can be accomplished on foot"
    ),
    new Score(4, "Very Walkable", "Most errands can be accomplished on foot"),
    new Score(5, "Walker's Paradise", "Daily errands do not require a car"),
  ],
  bike: [
    new Score(1, "Somewhat Bikeable", "Minimal bike infrastructure"),
    new Score(2, "Bikeable", "Some bike infrastructure"),
    new Score(3, "Very Bikeable", "Biking is convenient for most trips"),
    new Score(
      4,
      "Biker's Paradise",
      "Daily errands can be accomplished on bike"
    ),
  ],
  transit: [
    new Score(1, "Minimal Transit", "It is possible to get on a bus"),
    new Score(2, "Some Transit", "A few nearby public transportation options"),
    new Score(3, "Good Transit", "Many nearby public transportation options"),
    new Score(4, "Excellent Transit", "Transit is convenient for most trips"),
    new Score(5, "Rider's Paradise", "World-class public transportation"),
  ],
  driving: [
    new Score(
      1,
      "Challenging to Drive",
      "The area is difficult to navigate and may have limited access to roads or highways"
    ),
    new Score(
      2,
      "Some Obstacles",
      "There may be some obstacles to driving, such as narrow roads or heavy traffic"
    ),
    new Score(
      3,
      "Easy to Drive",
      "The location is generally easy to navigate and there are good road networks in the area"
    ),
    new Score(
      4,
      "Very Easy to Drive",
      "The location has excellent roads and infrastructure, making it very easy to get around by car"
    ),
    new Score(
      5,
      "Driver's Haven",
      "The location has some of the best driving conditions with wide, well-maintained roads, easy navigation, and low traffic congestion"
    ),
  ],
};

const Star = ({ filled }) => {
  if (!filled) {
    return (
      <li>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.51689 6.38124L7.25898 6.4178L2.88964 7.03726L2.88928 7.03731C2.50041 7.09215 2.38428 7.5307 2.63388 7.76704L2.63402 7.76718L5.79514 10.7627L5.9904 10.9477L5.9436 11.2126L5.196 15.4437C5.19599 15.4438 5.19598 15.4438 5.19597 15.4439C5.13918 15.7673 5.50476 16.077 5.8621 15.8928L5.86365 15.892L9.77243 13.894L10 13.7777L10.2276 13.894L14.1363 15.892L14.1364 15.892C14.4971 16.0764 14.8602 15.7646 14.8041 15.4441C14.8041 15.4441 14.8041 15.4441 14.8041 15.444C14.804 15.4439 14.804 15.4438 14.804 15.4437L14.0564 11.2126L14.0096 10.9477L14.2049 10.7627L17.366 7.76718L17.3661 7.76704C17.6157 7.5307 17.4996 7.09215 17.1107 7.03731L17.1104 7.03726L12.741 6.4178L12.4831 6.38124L12.3653 6.14891L10.4129 2.29933C10.4128 2.29922 10.4127 2.2991 10.4127 2.29899C10.2456 1.97198 9.75279 1.97343 9.58761 2.2984L7.51689 6.38124ZM7.51689 6.38124L7.63472 6.14891L9.58751 2.29859L7.51689 6.38124Z"
            fill="#7E6947"
            stroke="#7E6947"
          />
        </svg>
      </li>
    );
  } else {
    return (
      <li>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.51689 6.38124L7.25898 6.4178L2.88964 7.03726L2.88928 7.03731C2.50041 7.09215 2.38428 7.5307 2.63388 7.76704L2.63402 7.76718L5.79514 10.7627L5.9904 10.9477L5.9436 11.2126L5.196 15.4437C5.19599 15.4438 5.19598 15.4438 5.19597 15.4439C5.13918 15.7673 5.50476 16.077 5.8621 15.8928L5.86365 15.892L9.77243 13.894L10 13.7777L10.2276 13.894L14.1363 15.892L14.1364 15.892C14.4971 16.0764 14.8602 15.7646 14.8041 15.4441C14.8041 15.4441 14.8041 15.4441 14.8041 15.444C14.804 15.4439 14.804 15.4438 14.804 15.4437L14.0564 11.2126L14.0096 10.9477L14.2049 10.7627L17.366 7.76718L17.3661 7.76704C17.6157 7.5307 17.4996 7.09215 17.1107 7.03731L17.1104 7.03726L12.741 6.4178L12.4831 6.38124L12.3653 6.14891L10.4129 2.29933C10.4128 2.29922 10.4127 2.2991 10.4127 2.29899C10.2456 1.97198 9.75279 1.97343 9.58761 2.2984L7.51689 6.38124ZM7.51689 6.38124L7.63472 6.14891L9.58751 2.29859L7.51689 6.38124Z"
            stroke="#7E6947"
          />
        </svg>
      </li>
    );
  }
};

const LocationScoreList = (props) => {
  const transitType = props.type;
  const scores = ALL_SCORES[transitType] || [];
  const score = scores[props.score - 1];

  if (!score) return null;
  return (
    <LocationScorestyle>
      <div className="location-score__container">
        <h3 className="heading-6">{props.heading}</h3>
        <h4>{score.title}</h4>
        <p>{score.description}</p>
        <div className="stars-container">
          <ul>
            {Array.from(Array(scores.length), (_, index) => {
              const filled = score.stars < index + 1;
              return <Star key={index} filled={filled} />;
            })}
          </ul>
        </div>
      </div>
    </LocationScorestyle>
  );
};
function LocationScore(props) {
  if (!props.scores) return null;
  return (
    <div className="property-info__section">
      <h2 className="property-details__heading heading-5"> Location Score </h2>
      <div className="property-details__location-score">
        <LocationScoreList
          heading={"Walkability"}
          type={"walk"}
          score={props.scores.walking_score}
        />
        <LocationScoreList
          heading={"Bike Friendly"}
          type={"bike"}
          score={props.scores.bike_score}
        />
        <LocationScoreList
          heading={"Transit Friendly"}
          type={"transit"}
          score={props.scores.commute_score}
        />
        <LocationScoreList
          heading={"Driving Score"}
          type={"driving"}
          score={props.scores.driving_score}
        />
      </div>
    </div>
  );
}

export default LocationScore;
