import { useContext, useState, useEffect } from "react";
import {
  FilterContext,
  FilterDispatchContext,
} from "../../Context/FilterContext";

export const TRANSACTION_TYPES = [
  { value: "all", label: "Sale/Lease" },
  { value: "sale", label: "Sale" },
  { value: "lease", label: "Lease" },
];

export function TransactionType({ onFilterChange }) {
  const dispatch = useContext(FilterDispatchContext);
  const filters = useContext(FilterContext);

  const transactionType = filters?.transactionType || "all"; // Default to "all" if not available in filters
  const [activeButton, setActiveButton] = useState(transactionType);

  useEffect(() => {
    // This ensures that any external changes to the filter context update our local state.
    setActiveButton(transactionType);
  }, [transactionType]);

  const handleChange = (selectedOption) => {
    const value = selectedOption.value;

    onFilterChange("transactionType", value); // Update the local filter state in the parent component
    setActiveButton(value); // Update the activeButton state immediately
  };

  return (
    <>
      {TRANSACTION_TYPES.map((type) => (
        <button
          className={`${activeButton === type.value ? "active" : ""} ${
            type.value
          }`}
          key={type.value}
          onClick={() => handleChange(type)}
        >
          <span className="icon"></span>
          {type.label}
        </button>
      ))}
    </>
  );
}
