import { useContext, useEffect, useState } from "react";
import { FilterDispatchContext, FilterContext } from "../Context/FilterContext";
import { FilterModal } from "./FilterModal";
import { FilterIcon } from "../Util/SVGIcons";
import styled from "styled-components";

//import { SearchBarstyle } from "./PropertyFilter.style";
import { HeroFilter } from "./HeroFilter.style";

import { SearchByKeyword } from "./SearchByKeyword";

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  let obj = {};
  for (let [key, value] of params) {
    obj[key] = value;
  }
  return obj;
};

const PropertyFilterV2 = ({ clearPrevFilter }) => {
  const dispatch = useContext(FilterDispatchContext);
  const dispatchContext = useContext(FilterContext);

  const [isKeywordSearchVisible, setIsKeywordSearchVisible] = useState(false);
  const [activeButton, setActiveButton] = useState("buy");
  const [showPopup, setShowPopup] = useState(false);

  const transactionType = dispatchContext?.transactionType || "all";
  const propertyType = dispatchContext?.propertyType || "all";
  const location = dispatchContext?.location || "all";
  const price = dispatchContext?.price || "all";
  const collection = dispatchContext?.collection || "";
  const searchKeyword = dispatchContext?.searchKeyword || "";

  useEffect(() => {
    const params = getQueryParams();

    const transactionType =
      params.transactionType || dispatchContext?.transactionType || "all";
    const propertyType =
      params.propertyType || dispatchContext?.propertyType || "all";
    const location = params.location || dispatchContext?.location || "all";
    const price = params.price || dispatchContext?.price || "all";
    const collection = params.collection || dispatchContext?.collection || "";
    const searchKeyword =
      params.keyword || dispatchContext?.searchKeyword || "";

    const consolidatedFilters = {
      transactionType,
      propertyType,
      location,
      price,
      collection,
      searchKeyword,
    };

    // Create an instance of URLSearchParams for modifying the current query string
    const urlSearchParams = new URLSearchParams(window.location.search);

    Object.entries(consolidatedFilters).forEach(([key, value]) => {
      let isDelete = typeof value === "string" && value.toLowerCase() === "all";

      dispatch({
        action: isDelete ? "delete" : "set",
        filter: {
          key,
          value,
        },
      });

      // Update the URL with the new filter
      if (value && !isDelete) {
        urlSearchParams.set(key, value);
      } else {
        urlSearchParams.delete(key);
      }
    });

    // You may want to update the browser's history with the new URL
    // window.history.replaceState({}, '', `?${urlSearchParams.toString()}`);
    console.log("Dispatched Filters:", consolidatedFilters);
  }, []);

  const [filters, setFilters] = useState({
    // ... your filter state initialization
  });

  const clearFiltersConditionally = (shouldClear) => {
    if (shouldClear) {
      dispatch({
        action: "clear",
      });
    }
  };

  useEffect(() => {
    clearFiltersConditionally(clearPrevFilter);
  }, [clearPrevFilter]);

  const clearFilters = () => {
    dispatch({
      action: "clear",
    });
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    let isDelete = false;

    dispatch({
      action: isDelete ? "delete" : "set",
      filter: {
        key: "transactionType",
        value: buttonType,
      },
    });
  };

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };
  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("modal-open", buttonPopup);
  }, [buttonPopup]);

  return (
    <HeroFilter>
      <div className="search-bar search-bar__faceted">
        {/* Check for "sale" transactionType for Buy button */}
        <div
          className={`button-filter button-buy ${
            transactionType === "sale" ? "active" : ""
          }`}
        >
          <button onClick={() => handleButtonClick("sale")}>Buy</button>
        </div>

        {/* Check for "lease" transactionType for Lease button */}
        <div
          className={`button-filter button-lease ${
            transactionType === "lease" ? "active" : ""
          }`}
        >
          <button onClick={() => handleButtonClick("lease")}>Lease</button>
        </div>

        {/* <LocationFilter /> */}

        <SearchByKeyword
          transactionType={transactionType}
          propertyType={propertyType}
          location={location}
          collectionLength={collection.length}
          price={price}
          searchKeyword={searchKeyword}
        />
      </div>

      <div className="filter-btn">
        <button onClick={() => setButtonPopup(true)}>
          <FilterIcon />
          Filters
        </button>
      </div>

      <FilterModal
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
        updateFilters={updateFilters}
      ></FilterModal>
    </HeroFilter>
  );
};

export default PropertyFilterV2;
