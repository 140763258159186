import { useReducer } from "react";
import PropertyHelmet from "./Components/PropertyHelmet";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  FilterContext,
  FilterDispatchContext,
} from "./Components/Context/FilterContext";
import Footer from "./Components/Footer";
import GlobalStyles from "./Components/GlobalStyles";
import Header from "./Components/Header";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import Services from "./Components/Services";
import PropertyDetails from "./Components/PropertyDetails";
import { FilterReducer } from "./Components/Reducer/FilterReducer";
//import MobileBottomNav from "./Components/MobileBottomNav";
function App() {
  const initialFilters = JSON.parse(
    localStorage.getItem("PropellerPropertyFilters") || "{}"
  );
  const [filters, dispatch] = useReducer(FilterReducer, initialFilters);

  return (
    <>
      <PropertyHelmet {...PropertyDetails} />
      <div className="App">
        <FilterContext.Provider value={filters}>
          <FilterDispatchContext.Provider value={dispatch}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <BrowserRouter>
                <GlobalStyles />
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/property/:id" element={<PropertyDetails />} />
                  {/* <Route path="/search" element={<Search/>} /> */}
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/services" element={<Services />} />
                </Routes>
                {/* <MobileBottomNavstyle /> */}
                {/* <MobileBottomNav /> */}
                <Footer />
              </BrowserRouter>
            </SkeletonTheme>
          </FilterDispatchContext.Provider>
        </FilterContext.Provider>
      </div>
    </>
  );
}

export default App;
