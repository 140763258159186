import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import axios from "axios";
import { Navigation, Pagination } from "swiper";
import { FilterContext, FilterDispatchContext } from "./Context/FilterContext";

const IconsPath = "/Assets/icons/categories/";

const HeaderCategoriesStyle = styled.section`
  margin: 16px 0;

  &.sticky {
    margin: 16px 0;
  }

  @media screen and (min-width: 768px) {
    margin: 42px 0;

    &.sticky {
      margin: 16px 0;
    }
  }

  .categories-container {
    overflow: hidden;
    :after {
      width: 100px;
      height: 100px;
      position: absolute;
      content: "";
      right: 0;
      background-color: var(--color-body);
    }
  }

  .swiper {
    .swiper-slide {
      width: auto !important;
    }
    .swiper-button-disabled {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 100px;
      height: 32px;
      padding: 20px;
      //background-color:  var(--background-grey-90);
      height: 70px;
      top: 20px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 13px;
      font-weight: bold;
      color: var(--color-secondary);
      border-radius: 100%;
      border: 1px solid var(--color-secondary);
      text-align: center;
      width: 32px;
      height: 32px;
      padding: 10px 12px;
    }
    .swiper-button-next {
      right: -34px;
      align-self: flex-end;
      background: rgb(250, 250, 250);
      background: linear-gradient(
        97deg,
        rgba(250, 250, 250, 0) 0%,
        rgba(250, 250, 250, 1) 48%,
        rgba(250, 250, 250, 1) 100%
      );
    }
    .swiper-button-prev {
      left: -34px;
      align-self: flex-start;
      background: rgb(250, 250, 250);
      background: linear-gradient(
        97deg,
        rgba(250, 250, 250, 1) 48%,
        rgba(250, 250, 250, 0) 100%
      );
    }
  }

  button {
    background-color: transparent;
    border: none;
    font-family: var(--font-secondary);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .category-icon {
      min-height: 45px;
      margin: 0 auto;

      img {
        display: block;
        filter: brightness(0) contrast(0.3);
      }
    }

    :hover span,
    span.selected {
      border-bottom: 2px solid var(--color-secondary);
      color: var(--color-secondary);
    }

    span {
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      display: inline-block;
      white-space: nowrap;
      align-self: flex-end;
    }

    img {
      margin: 0 auto 5px auto;
      display: block;
    }
  }
  button.selected {
    img {
      -webkit-filter: brightness(80%) sepia(100%);
      filter: brightness(80%) sepia(100%);
    }
  }
`;

const HeaderCategoryNav = ({ activeClass }) => {
  const filters = useContext(FilterContext);
  const dispatch = useContext(FilterDispatchContext);
  const [collections, setCollections] = useState([]);
  const selectedFeature = filters?.collection || null;

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/collections/`;
    axios.get(url).then((response) => {
      const data = response.data;
      setCollections(data.results);
    });
  }, []);

  const handleFilterChange = (value) => {
    let isDelete = false;
    if (value === selectedFeature) {
      isDelete = true;
    }
    dispatch({
      action: isDelete ? "delete" : "set",
      filter: { key: "collection", value: value },
    });
  };

  //console.log(selectedFeature);

  if (collections.length === 0) {
    return null;
  }

  return (
    <HeaderCategoriesStyle className={activeClass}>
      <div className="container">
        <div className="categories-container">
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={"auto"}
            spaceBetween={8}
            navigation={true}
            loop={true}
            breakpoints={{
              768: {
                spaceBetween: 28,
                //navigation: false,
              },
              1200: {
                spaceBetween: 28,
                //navigation: true,
              },
            }}
          >
            {collections.map((category, index) => (
              <SwiperSlide key={index}>
                <button
                  id={index}
                  type="submit"
                  value={category.slug}
                  className={
                    selectedFeature === category.slug ? "selected" : ""
                  }
                  onClick={() => {
                    handleFilterChange(category.slug);
                  }}
                >
                  <div className="category-icon">
                    <img src={IconsPath + category.icon + ".svg"} alt="" />
                  </div>
                  <span
                    className={
                      selectedFeature === category.slug ? "selected" : ""
                    }
                  >
                    {category.name}
                  </span>
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </HeaderCategoriesStyle>
  );
};
export default HeaderCategoryNav;
