import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeroBg from "../Assets/hero-bg.png";
import HeaderCategoryNav from "./HeaderCategoriesNav";
import ActiveFiltersList from "./Home/ActiveFilters";
import PropertyFilterV2 from "./Home/PropertyFilter-v2";
import PropertiesGrid from "./PropertiesGrid";

const HomeStyle = styled.section`
  .hero-header-nav {
    z-index: 9;
    background-color: var(--background-grey-90);
    width: 100%;

    &.sticky {
      position: fixed;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05),
        0px 1px 5px rgba(0, 0, 0, 0.05);

      .hero-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        padding-bottom: 0;
      }
    }
  }
`;

const HeroStyle = styled.div`
  background-image: url(${HeroBg});
  background-color: var(--background-grey-90);
  background-position: bottom center;
  position: relative;
  transition: all linear 200ms;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.sticky {
    opacity: 0.8;

    .container {
      padding-top: 112px;
      padding-bottom: 42px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 154px;
    flex-direction: column;
    padding-bottom: 24px;
    transition: all linear 200ms;
  }
`;

const Home = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <HomeStyle>
        <div className={`hero-header-nav ${isSticky ? "sticky" : ""}`}>
          <HeroStyle className={`container ${isSticky ? "sticky" : ""}`}>
            <div className="container hero-container">
              <PropertyFilterV2 className="hero-header__search" />
              <ActiveFiltersList />
            </div>
          </HeroStyle>
          <HeaderCategoryNav activeClass={`${isSticky ? "sticky" : ""}`} />
        </div>
        <PropertiesGrid activeClass={`${isSticky ? "sticky" : ""}`} />
      </HomeStyle>
    </>
  );
};
export default Home;
