import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    :root {
        --container-max-width: 1440px;
        --color-primary: #D5A041;
        --color-secondary: #7E6947;
        --color-accent: #3A7C8A;
        --color-secondary-200: #BFB4A3;
        --color-secondary-300: #9E8E75;
        --color-secondary-500: #7E6947;
        --color-secondary-600: #5E4319;
        --text-black-300: #5E676F;
        --color-black-500: #272A2D;
        --color-white: #fff;
        --color-dark: #323A41;
        --font-primary: 'Lato', sans-serif;
        --font-secondary: 'Nunito Sans', sans-serif;
        --background-grey-100: #F2F2F2;
        --background-grey-200: #E6E6E6;
        --background-grey-90: #FAFAFA;
        --background-grey-400: #CCCCCC;
        --background-grey-500: #BFBFBF;
        --primary-100: #F7ECD9;
        --primary-grey-100: #BFC5CC;
        --primary-grey-200: #939DAC;
        --primary-grey-300: #5E676F;
        --primary-grey-500: #272A2D;
        --elevation-1dp: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);
        --elevation-2dp:  0px 2px 10px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.08);
        --elevation-4dp: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.08);
        --accent-teal-500: #3A7C8A;
    }

   
    html {
        border-sizing: border-box;
        font-size: 16px;
        line-height: 24px;
        background-color: var(--background-grey-90);
    }
    
    body.modal-open,
    body.sidebar-open {
        position: fixed;
        width: 100%;

        header {
            position: unset;
        }
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }
    .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
        font-weight: bold;
        font-family: var(--font-primary);
        color: var(--color-black-500);
    }
    .heading-1 {
        font-size: 72px;
        line-height: 84px;
        color: var(--color-black-500);
    }
    .heading-3 {
        font-size: 48px;
        line-height: 56px;
        color: var(--color-black-500);

        @media screen and (min-width: 992px) {
            font-size: 32px;
            line-height: 40px;
        }
    }
    .heading-4 {
        font-size: 24px;
        line-height: 32px;

        @media screen and (min-width: 992px) {
            font-size: 32px;
            line-height: 40px;
        }
    }
    .heading-5 {
        font-size: 24px;
        line-height: 32px;
    }
    .heading-6 {
        font-size: 20px;
        line-height: 24px;
        color: var( --text-black-300)
    }
    ul, li {
        list-style: none;
        padding: 0;
    }

    a {
        color: var(--color-secondary);
        text-decoration: none;
    }

    .logo img {
        max-width: 200px;
    }
    .badge {
        display: inline-block;
        padding: 8px 16px;
        background-color: var(--color-primary);
        border-radius: 100px;
        padding: 8px 16px;
        color: #fff;
        line-height: 1;
    }
    .capitalize {
        text-transform: capitalize;
    }
    .container {
        max-width: var(--container-max-width);
        margin: 0 16px;

        @media only screen and (min-width: 1440px) {
            margin: 0 auto;
            padding: 0 56px;
        }

    
    .text-center {
        text-align: center;

    }
    .inf-scrl_prop-counter {
        text-align: center;
        padding: 32px 0;
        color: var(--color-secondary)
    }
    /* .containerPDF {
        display: none;
    } */
    @media print {
  body * {
    visibility: hidden;
    position: relative;
    margin: 0;
  }
  .containerPDF, .containerPDF * {
    visibility: visible;
    display: block;
  }
  .containerPDF {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.rc-slider {
    margin: 40px auto;
    max-width: 90%;
}
/* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: var(--primary-grey-500) #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--primary-grey-100);
      border-radius: 10px;
      border: 3px none #ffffff;
    }
`;

export default GlobalStyles;
