import React, { useRef, useState } from "react";
import styled from "styled-components";

const Servicesstyle = styled.section`
  @media only screen and (min-width: 768px) {
    min-height: 328px;
  }

  section {
    padding: 40px 0;

    @media only screen and (min-width: 768px) {
      padding: 60px 0;
    }

    img {
      max-width: 100%;
    }
  }

  p {
    color: var(--text-black-300);
    margin: 24px 0;
  }

  .services-section {
    background-repeat: no-repeat;
  }

  .bg-alt-1,
  .bg-alt-2 {
    background-image: none;
  }

  .bg-alt-2 {
    background-color: var(--background-grey-100);
  }

  @media only screen and (min-width: 768px) {
    .bg-alt-1 {
      background-image: url("../Assets/services/bg-alt-1.png");
      background-position: top right;
    }
    .bg-alt-2 {
      background-color: #fff;
      background-color: var(--background-grey-100);
      background-image: url("../Assets/services/bg-alt-2.png");
      background-position: top left;
    }
  }

  .services__hero {
    background-image: url("../Assets/services/hero.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    min-height: 288px;
    margin-top: 77px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 768px) {
      min-height: 450px;
    }
  }

  h1.heading-1,
  h2.heading-3 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    line-height: 40px;
  }

  @media only screen and (min-width: 768px) {
    h1.heading-1 {
      font-size: 72px;
      line-height: 84px;
    }
    h2.heading-3 {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .grid__container {
    margin-top: 32px;
    max-width: 1100px;
    margin: 0 16px;
    padding: 0 16px;
    display: grid;
    grid-gap: 40px;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;
    align-items: center;

    @media only screen and (min-width: 768px) {
      margin: 0 auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 120px;
    }

    .col-text {
      max-width: 100%;
      order: 1;

      @media only screen and (min-width: 768px) {
        max-width: 80%;
        order: 2;
      }
    }
    .col-img {
      order: 2;
    }

    h2 {
      color: var(--color-black-500);
      margin-bottom: 0;
    }

    .btn {
      display: block;
      border: none;
      width: auto;
      font-size: 16px;
      line-height: 32px;
      font-family: var(--font-secondary);
      padding: 8px 16px;
      border-radius: 100px;
      color: #fff;
      text-align: center;
      transition: 0.3s ease-out;
      position: relative;
      overflow: hidden;
      background-color: transparent;
      z-index: 1;

      a {
        display: block;
        z-index: 2;
        color: #fff;
      }

      @media only screen and (min-width: 768px) {
        display: inline-block;
        font-size: 20px;
        line-height: 32px;
      }

      &,
      &::after {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }

      &::before {
        background-color: #ddb367;
        width: 100%;
        content: "";
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
      }

      &::after {
        background-color: var(--color-secondary);
        content: "";
        position: absolute;
        z-index: -1;
      }

      &::after {
        height: 100%;
        left: 0;
        top: 0;
        width: 0;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
  .section__project-selling {
    background-color: var(--color-secondary);
    margin-top: 192px;

    .section2__container {
      max-width: 1344px;
      margin: 0 auto;
    }
  }
  .project-selling-img {
    margin-top: -250px;
    padding-bottom: 60px;
    padding: 0 16px;

    .img-mob {
      width: 100%;
    }
    .img-desktop {
      display: none;
    }

    @media only screen and (min-width: 768px) {
      .img-mob {
        display: none;
      }
      .img-desktop {
        display: block;
      }
    }
  }
  .project-selling-logos {
    gap: 16px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;

    img {
      margin: 16px 0;
    }

    @media only screen and (min-width: 768px) {
      display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-template-columns: 180px 180px 180px;
      grid-template-rows: auto;
      grid-gap: 20px;
    }
    @media only screen and (min-width: 768px) {
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(170px, 2fr));
    }
    @media only screen and (min-width: 1400px) {
      grid-template-columns: repeat(auto-fit, minmax(170px, 2fr));
    }
  }
  .project-selling-text {
    margin-top: 30px;
  }
  .project-selling-text * {
    color: #fff;
  }
  .project-selling-btn {
    padding: 30px 0;

    a {
      border-color: #fff;
      display: inline-block;
      margin: 0 auto;
      border: 1px solid #fff;
      padding: 8px 16px;
      border-radius: 100px;
    }
  }
  .text-center {
    text-align: center;
  }
`;
function openFormPopup() {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLScr_mdaYyQTyupt-RKCRfKZcaXA3G4qPvjc2WcU0ZsO-nTb5w/viewform?embedded=true",
    "formPopup",
    "width=600,height=800"
  );
}

const Services = () => {
  return (
    <React.Fragment>
      <Servicesstyle>
        <section className="services__hero">
          <div className="container">
            <h1 className="heading-1 text-center">
              Services at
              <br /> Propeller Realty
            </h1>
          </div>
        </section>

        <section className="services-section bg-alt-1">
          <div className="container grid__container">
            <div className="col-2 col-text">
              <h2 className="heading-3">General Brokerage</h2>
              <p>
                Sales and lease of residential and commercial properties in
                prime Metro Manila and select CALABARZON and Central Luzon
                areas.
              </p>
              <button className="btn" onClick={openFormPopup}>
                <a href="#">Inquire Now</a>
              </button>
            </div>
            <div className="col-2 col-img">
              <img src={"/Assets/services/general-brokerage.png"} />
            </div>
          </div>
        </section>

        <section className="section__project-selling animate__animated animate__fadeInLeft animate__delay-4s">
          <div className="section2__container">
            <div className="project-selling-img">
              <img
                src={"/Assets/services/project-selling-mob.png"}
                className="img-mob"
              />
              <img
                src={"/Assets/services/project-selling.png"}
                className="img-desktop"
              />
            </div>
            <div className="project-selling-text text-center">
              <h2 className="heading-3 text-center">Project Selling</h2>
              <p>
                Sale of Rent to Own Preselling and Ready fo Occupancy (RFO)
                residential, office and commercial properties.
              </p>
              <div className="project-selling-logos">
                <div className="logo-image">
                  <img src={"/Assets/services/logo-ayala.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-rockwell-land.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-ortigas-and-company.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-shang-properties.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-megaworld.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-sta-lucia.png"} />
                </div>
                <div className="logo-image">
                  <img src={"/Assets/services/logo-dmci.png"} />
                </div>
              </div>
              <div className="project-selling-btn">
                <a href="#" onClick={openFormPopup}>
                  Inquire about projects
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="services-section bg-alt-2">
          <div className="container grid__container">
            <div className="col-2 col-img">
              <img src={"/Assets/services/documentation-and-transfer.png"} />
            </div>
            <div className="col-2 col-text">
              <h2 className="heading-3">
                Documentation, Processing and Transfers
              </h2>
              <p>
                General real estate property processing, documentation and
                transfer, business registration and processing services.
              </p>
              <button className="btn" onClick={openFormPopup}>
                <a href="#">Inquire Now</a>
              </button>
            </div>
          </div>
        </section>
        <section className="services-section bg-alt-1">
          <div className="container grid__container">
            <div className="col-2 col-text">
              <h2 className="heading-3">
                Home Loan
                <br /> Assistance
              </h2>
              <p>
                End-to-end home loan assistance with major commercial banks.
              </p>
              <button className="btn" onClick={openFormPopup}>
                <a href="#">Inquire Now</a>
              </button>
            </div>
            <div className="col-2 col-img">
              <img src={"/Assets/services/home-loan-assistance.png"} />
            </div>
          </div>
        </section>
        <section className="services-section bg-alt-2">
          <div className="container grid__container">
            <div className="col-2 col-img">
              <img src={"/Assets/services/non-life-insurance.png"} />
            </div>
            <div className="col-2 col-text">
              <h2 className="heading-3">Non-life Insurance</h2>
              <p>
                Fire Insurance, Heir’s Bond, Construction Bond, Professional
                Surety Bond and other non-life insurance needs.
              </p>
              <button className="btn" onClick={openFormPopup}>
                <a href="#">Inquire Now</a>
              </button>
            </div>
          </div>
        </section>
        <section className="services-section bg-alt-1">
          <div className="container grid__container">
            <div className="col-2 col-text">
              <h2 className="heading-3">Digital Media Production</h2>
              <p>
                Photo, video, and 360 degrees virtual tours of real estate
                clients and sales agents.
              </p>
              <button className="btn" onClick={openFormPopup}>
                <a href="#">Inquire Now</a>
              </button>
            </div>
            <div className="col-2 col-img">
              <img src={"/Assets/services/digital-media-production.png"} />
            </div>
          </div>
        </section>
      </Servicesstyle>
    </React.Fragment>
  );
};

export default Services;
