import React from 'react';

function PropertyDetailsAmenities(props) {
    let amenities = props.amenities || [];
    if (amenities.length === 0) return null;
    return (
        <div className="property-info__section">
            <h2 className="property-details__heading heading-5">
                Structure Features and Amenities
            </h2>

            <div className="property-info_features">
                <ul>
                    {
                        amenities.map((amenity, index) =>
                            <li key={index}>
                                <img src={"/Assets/icons/categories/" + amenity.slug + ".svg"} alt={amenity.name} />
                                {amenity.name}
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

export default PropertyDetailsAmenities
