import React, { useContext, useState, useEffect } from "react";
import {
  FilterContext,
  FilterDispatchContext,
} from "../../Context/FilterContext";
import axios from "axios";

export function Location({ onFilterChange }) {
  const filters = useContext(FilterContext);
  const currentLocation = filters?.location || "all";
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [activeButton, setActiveButton] = useState(currentLocation); // Set the initial state to the current filter value

  useEffect(() => {
    async function fetchCities() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cities/`
        );
        const cities = response.data.results;

        // Sort city names alphabetically
        const sortedCities = cities.sort();

        // Create city options with state information
        const cityOptions = sortedCities.map((city) => ({
          label: city,
          value: city,
        }));

        setCities([{ label: "All", value: "all" }, ...cityOptions]);
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false); // Set loading state to false
      }
    }

    fetchCities();
  }, []);

  const handleChange = (selectedCity) => {
    const value = selectedCity.value;

    onFilterChange("location", value); // Update the local filter state in the parent component

    setActiveButton(value);
  };

  return (
    <>
      {cities.map((city) => (
        <button
          className={`${activeButton === city.value ? "active" : ""} ${
            city.value
          }`}
          key={city.value}
          onClick={() => handleChange(city)}
        >
          {city.label}
        </button>
      ))}
    </>
  );
}
