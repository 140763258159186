import React, { useState, useContext, useRef } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import {
  FilterContext,
  FilterDispatchContext,
} from "../../Context/FilterContext";

export function PriceRange({ onFilterChange }) {
  const dispatch = useContext(FilterDispatchContext);
  const filters = useContext(FilterContext);

  const formatNumber = (num) => {
    return num.toLocaleString("en-US");
  };

  const parseFormattedNumber = (str) => {
    return parseInt(str.replace(/,/g, ""), 10);
  };

  const [value, setValue] = useState([
    filters?.min_price || 500000,
    filters?.max_price || 100000000,
  ]);

  const minInputRef = useRef(null);
  const maxInputRef = useRef(null);

  const handleSliderChange = (newValue) => {
    setValue(newValue);
    minInputRef.current.value = formatNumber(newValue[0]);
    maxInputRef.current.value = formatNumber(newValue[1]);
    onFilterChange("min_price", newValue[0]);
    onFilterChange("max_price", newValue[1]);
  };

  const handleInputBlur = (type) => {
    const parsedMin = parseFormattedNumber(minInputRef.current.value);
    const parsedMax = parseFormattedNumber(maxInputRef.current.value);
    setValue([parsedMin, parsedMax]);
    onFilterChange("min_price", parsedMin);
    onFilterChange("max_price", parsedMax);

    // Format the numbers in the input fields
    minInputRef.current.value = formatNumber(parsedMin);
    maxInputRef.current.value = formatNumber(parsedMax);
  };

  const handleStyle = {
    backgroundColor: "#F7ECD9",
    borderColor: "#9E8E75",
    width: "36px",
    height: "36px",
    opacity: "1",
    top: "-5px",
  };

  const trackStyle = {
    backgroundColor: "#b59b6a",
    height: "4px",
  };

  const railStyle = {
    backgroundColor: "#e3e3e3",
    height: "4px",
    marginTop: "0px",
  };

  const SliderRangeStyle = styled.div`
    .slider-range {
      max-width: 90%;
      margin: 40px auto;
    }
    .range-inputs {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      align-items: center;

      @media screen and (min-width: 768px) {
        display: flex;
        max-width: 90%;
      }

      hr {
        min-width: 38px;
        height: 1px;
        margin: 16px 0;
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      .range-input {
        border-radius: 8px;
        border: 1px solid var(--primary-grey-100);
        min-width: 40%;
        padding: 16px;
        position: relative;
        margin-bottom: 16px;

        span,
        input {
          display: inline;
          font-size: 16px;
          border: none;
        }

        label {
          display: inline-block;
          color: #d5a041;
          font-size: 12px;
          position: absolute;
          top: -15px;
          padding: 3px;
          background: #fff;
        }
      }
    }
    .rc-slider-handle:active {
      box-shadow: none;
    }
  `;

  return (
    <>
      <Slider
        range
        min={100000}
        max={2000000000}
        handleStyle={[handleStyle, handleStyle]}
        trackStyle={[trackStyle]}
        railStyle={railStyle}
        className="slider-range"
        value={value}
        onChange={handleSliderChange}
        step={500000}
      />
      <SliderRangeStyle>
        <div className="range-inputs">
          <div className="range-input">
            <label>Minimum:</label> ₱
            <input
              type="text"
              ref={minInputRef}
              defaultValue={formatNumber(value[0])}
              onBlur={() => handleInputBlur("min")}
            />
          </div>
          <hr />
          <div className="range-input">
            <label>Maximum:</label> ₱
            <input
              type="text"
              ref={maxInputRef}
              defaultValue={formatNumber(value[1])}
              onBlur={() => handleInputBlur("max")}
            />
          </div>
        </div>
      </SliderRangeStyle>
    </>
  );
}
