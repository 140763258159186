import React from 'react'
import styled from 'styled-components'

const NoResultsstyle = styled.div`
    text-align: center;
    padding: 50px 0;

    .no-results-inner {
        display: block;
        width: auto;
        margin: 0 auto;
    }
    .btn-contactus {
        background-color: #DDB367;
        border-radius: 100px;
        padding: 8px 16px;
        color: #323A41;
        margin: 16px 0;
    }

`

export default function NoResultsFound({count}) {
    return (count === 0) ? (
        <NoResultsstyle>
            <div className='container'>
                <div className="no-results-inner">
                    <svg width="249" height="185" viewBox="0 0 249 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="112.434" cy="67.7544" r="66.7544" fill="#BFB4A3" stroke="#7E6947" strokeWidth="2" />
                        <circle cx="112.433" cy="67.7544" r="47.396" fill="#F7ECD9" stroke="#9E8E75" strokeWidth="2" />
                        <path d="M112.433 32.174C112.433 31.4633 111.857 30.8849 111.146 30.9097C102.255 31.22 93.7576 34.738 87.2406 40.8376C80.7236 46.9372 76.6517 55.1831 75.7543 64.035C75.6827 64.742 76.2218 65.3556 76.9309 65.4025C77.64 65.4495 78.2505 64.9121 78.324 64.2052C79.177 56.0052 82.9591 48.3699 88.9993 42.7166C95.0394 37.0633 102.908 33.7941 111.147 33.485C111.857 33.4583 112.433 32.8847 112.433 32.174Z" fill="#9E8E75" />
                        <rect x="164.836" y="139.604" width="20.9437" height="65.1455" rx="10.4718" transform="rotate(-45 164.836 139.604)" fill="#BFB4A3" stroke="#7E6947" strokeWidth="2" />
                        <rect x="187.344" y="144.925" width="3.07575" height="25.7893" rx="1.53788" transform="rotate(-45 187.344 144.925)" fill="#EED9B3" />
                        <rect x="161.773" y="123.964" width="3.15728" height="12.6291" transform="rotate(-45 161.773 123.964)" fill="#7E6947" />
                        <rect x="195.273" y="59.8037" width="31.5728" height="3.15728" rx="1.57864" fill="#DDB367" />
                        <rect x="0.0537109" y="85.9224" width="31.5728" height="3.15728" rx="1.57864" fill="#DDB367" />
                        <rect x="233.16" y="59.8037" width="15.7864" height="3.15728" rx="1.57864" fill="#DDB367" />
                        <rect x="203.166" y="71.3877" width="15.7864" height="3.15728" rx="1.57864" fill="#DDB367" />
                        <rect x="7.9469" y="97.5063" width="15.7864" height="3.15728" rx="1.57864" fill="#DDB367" />
                    </svg>

                    <h3 className='heading-4'>Sorry we couldn’t find any properties for your search</h3>
                    <p>Try adjusting your search or filters to find what you are looking for.</p>
                    <p>Need assistance?</p>
                    <a href="#" className='btn-contactus'>Contact us</a>
                </div>
            </div>
        </NoResultsstyle>
    ) : '';
}

