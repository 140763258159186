import React from "react";
import { Helmet } from "react-helmet";

function PropertyHelmet(props) {
  const MAX_DESCRIPTION_LENGTH = 150;

  const truncatedDescription =
    props.description && props.description.length > MAX_DESCRIPTION_LENGTH
      ? `${props.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
      : props.description;

  return (
    <>
      <Helmet>
        <title>{props.title || "Propeller PH"}</title>
        <meta
          name="description"
          content={truncatedDescription || "Your trusted property seller"}
        />
        <meta property="og:title" content={props.title} />
        <meta
          property="og:description"
          content={truncatedDescription || "Your trusted property seller"}
        />
        <meta
          property="og:image"
          content={
            props.media && props.media.length > 0 ? props.media[0].file : ""
          }
        />

        <meta
          property="og:site_name"
          content="Propeller PH - Your trusted property seller"
        />
      </Helmet>
    </>
  );
}

export default PropertyHelmet;
