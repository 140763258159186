import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ListingPrice from "./Util/ListingPrice";
import Skeleton from "react-loading-skeleton";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Pagination } from "swiper";

const PropertyCardstyle = styled.div`
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05));
  background-color: #fff;
  min-width: 200px;
  border-radius: 15px;
  overflow: hidden;

  .properties-card-container {
    z-index: -1;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .property-card {
    position: relative;

    .property-card__img {
      .swiper {
        z-index: 99;

        .swiper-slide {
          height: 200px;
        }
        .swiper-button-next,
        .swiper-button-prev {
          top: 55%;
          width: 30px;
          height: 30px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 100%;

          &:after {
            font-size: 10px;
            color: var(--color-secondary);
          }
        }
        .swiper-button-disabled {
          visibility: hidden;
        }
        .swiper-pagination-bullet {
          background: #fff;
          opacity: 0.5;
        }
        .swiper-pagination-bullet-active {
          transform: scale(1.3);
          opacity: 1;
        }
      }

      .swiper-slide {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .property-card__badge {
      position: absolute;
      margin: 15px;

      border-radius: 100px;
      padding: 8px 16px;
      color: #fff;
      line-height: 1;
      font-size: 12px;
      font-weight: 700;
      z-index: 999;
      text-transform: uppercase;
    }
    .badge-sale {
      background-color: var(--color-primary);
    }
    .badge-lease {
      background-color: var(--color-secondary-600);
    }
    .badge-any {
      background-color: var(--accent-teal-500);
    }
    .property-card__details {
      padding: 20px;

      h2,
      h3 {
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 900;
        line-height: 24px;
        margin: 0;
        transition: all linear 200ms;
      }
      h2 {
        color: #323a41;
      }
      h3 {
        color: var(--color-primary);
      }

      .property-card__icons {
        display: flex;
        padding: 10px 0;
        align-items: center;
        gap: 15px;
      }

      .property-card__details--icon {
        display: flex;
        font-size: 12px;

        img {
          margin-right: 10px;
        }
      }

      &:hover {
        h2,
        h3 {
          color: var(--accent-teal-500);
        }
      }
    }
  }
`;

function PropertyCard({ listing: property }) {
  const IconsPath = "/Assets/icons/property";
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!property || !property.listing) {
    return null;
  }

  const {
    id,
    title,
    bedroom_count,
    parking_space_count,
    floor_area,
    lot_area,
  } = property;

  const listing = property.listing;

  const sale_price = parseInt(listing.sale_price || 0);
  const lease_price = parseInt(listing.monthly_lease_price || 0);

  const seo_friendly_title = title
    .replace(/[^\p{L}\p{N}]+/gu, "-")
    .toLowerCase();

  const transaction_type =
    listing.transaction_type === "any"
      ? "Sale / Lease"
      : listing.transaction_type;

  const area = floor_area ? floor_area : lot_area;
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <PropertyCardstyle>
      <Link to={`/property/${id}-${seo_friendly_title}`} aria-hidden="true">
        <div className="property-card">
          <span
            className={`property-card__badge ${
              listing.transaction_type === "any"
                ? "badge-any"
                : listing.transaction_type === "lease"
                ? "badge-lease"
                : "badge-sale"
            }`}
          >
            {transaction_type}
          </span>
          <div className="property-card__img">
            <Swiper
              autoplay={true}
              slidesPerView={1}
              navigation
              loop={false}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
            >
              {property.media.slice(0, 5).map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image.file} onLoad={handleImageLoad} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="property-card__details">
            <h2>
              {title.substring(0, 60)} {title.length >= 60 && "..."}
            </h2>
            <div className="property-card__icons">
              <span className="property-card__details--icon ico-bedroom">
                <i>
                  <img src={`${IconsPath}/ico-bedroom-sm.svg`} alt="" />
                </i>
                {bedroom_count}
              </span>
              <span className="property-card__details--icon ico-garage">
                <i>
                  <img src={`${IconsPath}/ico-garage-sm.svg`} alt="" />
                </i>
                {parking_space_count}
              </span>
              {area && (
                <span className="property-card__details--icon ico-area">
                  <i>
                    <img src={`${IconsPath}/ico-area-sm.svg`} alt="" />
                  </i>
                  {parseInt(area)} sqm
                </span>
              )}
            </div>
            <h3>
              <ListingPrice
                transaction_type={listing.transaction_type}
                sale_price={listing.sale_price}
                lease_price={listing.monthly_lease_price}
              />
            </h3>
          </div>
        </div>
      </Link>
    </PropertyCardstyle>
  );
}

export default PropertyCard;
