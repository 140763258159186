import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import PropertyDetailsMobileBottomNav from "./PropertyDetails/PropertyDetails.MobileBottomNav";
import {
  PropertyStructureIcon,
  LocationPinIcon,
  ExportIcon,
  GarageIcon,
  LotAreaIcon,
  FloorAreaIcon,
} from "./Util/SVGIcons";

import PropertyFilterV2 from "./Home/PropertyFilter-v2";
import PropertyDetailsAmenities from "./PropertyDetails/PropertyDetails.Amenities";
import PropertyDetailsContact from "./PropertyDetails/PropertyDetails.Contact";
import PropertyDetailsDesc from "./PropertyDetails/PropertyDetails.Desc";
import PropertyDetailsFeatures from "./PropertyDetails/PropertyDetails.Features";
import PropertyDetailsGallery from "./PropertyDetails/PropertyDetails.Gallery";
import LocationScore from "./PropertyDetails/PropertyDetails.LocationScore";
import PropertyDetailsMap from "./PropertyDetails/PropertyDetails.Map";
import TemplatePDF from "./PropertyDetails/TemplatePDF";
import ListingPrice from "./Util/ListingPrice";
import PropertyHelmet from "./PropertyHelmet";

import {
  PropertyDetailsstyle,
  SuggestedPropertiesstyle,
  SlideInRight,
} from "./PropertyDetails/PropertyDetails.Style";

//import SuggestedPropertiesGrid from "./SuggestedPropertiesGrid";

const PropertyDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, window);

  const [PropertyDetails, setPropertyDetails] = useState({
    listing: { transaction_type: "" },
  });

  const [PropertyDetailsImages, setPropertyImgs] = useState([]);
  const [PropertyDetailsMedia360, setPropertyMedia360] = useState(null);

  const [isContactActive, setIsContactActive] = useState(false);

  const [PropertyDetailsLocationScore, setPropertyDetailsLocationScore] =
    useState(null);

  // Add null check here
  const CurrentPath = window.location.pathname.split("/");
  const CurrentPathId = CurrentPath[2]?.split("-")[0];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/properties/${CurrentPathId}`)
      .then((res) => {
        console.log(res);
        setPropertyDetails(res.data);
        setPropertyImgs(res.data.media);
        setPropertyDetailsLocationScore(res.data.location_score);
        const video360Media = res.data.media.find(
          (mediaItem) => mediaItem.media_type === "video_360"
        );
        const video360Link = video360Media ? video360Media.external_link : null;
        setPropertyMedia360(video360Link);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const PropertyListingType = PropertyDetails.listing;
  function getTransactionType() {
    let type = PropertyListingType.transaction_type;

    if (type === "any") {
      type = "Sale / Lease";
    }

    return <a href={`/?q=1&transactionType=${type}`}>{type}</a>;
  }

  function getPropertyType() {
    const PropertyDetailsType = PropertyDetails.type;

    if (PropertyDetailsType) {
      return (
        <a href={`/?q=1&propertyType=${PropertyDetailsType}`}>
          {PropertyDetailsType.replace(/_/g, " ")}
        </a>
      );
    }

    return null;
  }

  const formattedLotArea = new Intl.NumberFormat("en-US").format(
    PropertyDetails.lot_area
  );

  const isPropertyDetailsLoaded = () => {
    return (
      PropertyDetails.title && PropertyDetails.city && PropertyDetails.state
    );
  };

  const areBreadcrumbsLoaded = () => {
    return getPropertyType() && getTransactionType() && PropertyDetails.city;
  };
  const isAddressLoaded = () => {
    return PropertyDetails.address_line_1 || PropertyDetails.address_line_2;
  };
  const targetRef = useRef(null);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Open the PDF
  const handlePrint = () => {
    setTimeout(() => {
      document.title = PropertyDetails.title;
      window.print();
    }, 500);
  };
  useEffect(() => {
    document.body.classList.toggle("modal-open", isContactActive);
  }, [isContactActive]);
  return (
    <>
      <PropertyHelmet {...PropertyDetails} />

      <TemplatePDF {...PropertyDetails} />
      <SkeletonTheme baseColor="#F2F2F2" highlightColor="#f6f6f6">
        <PropertyDetailsstyle>
          <section className="inner__search-bar">
            <div className="container">
              <div className="search-bar__inner">
                <PropertyFilterV2 clearPrevFilter={true} />
              </div>
            </div>
          </section>
          <div className="container property-detail__container">
            <div className="property-detail__breadcrumbs capitalize">
              {areBreadcrumbsLoaded() ? (
                <>
                  {getPropertyType()} / {getTransactionType()} /{" "}
                  <a href={`/?q=1&location=${PropertyDetails.city}`}>
                    {PropertyDetails.city}
                  </a>
                </>
              ) : (
                <Skeleton width={300} height={20} />
              )}
            </div>

            <div className="property-detail__title">
              <h1 className="heading-4">
                {
                  isPropertyDetailsLoaded() ? (
                    <>
                      {PropertyDetails.title}, <br />
                      {PropertyDetails.city}, {PropertyDetails.state}
                    </>
                  ) : (
                    <Skeleton width={300} height={30} />
                  ) // Adjust the width and height based on your design
                }
              </h1>
            </div>

            <div className="property-detail__sub-heading">
              <div className="property-detail__pin" onClick={handleScroll}>
                {isAddressLoaded() ? (
                  <span className="property-detail__icon">
                    <LocationPinIcon /> {PropertyDetails.address_line_1}{" "}
                    {PropertyDetails.address_line_2}
                  </span>
                ) : (
                  <Skeleton width={200} height={20} /> // Adjust the width and height as needed
                )}
              </div>

              <div className="property-detail__actions">
                {/*
                <button className="property-detail__icon">
                  <SaveIcon />
                  Save
                </button> */}

                <button className="property-detail__icon" onClick={handlePrint}>
                  <ExportIcon />
                  Export
                </button>
              </div>
            </div>

            {PropertyDetailsImages && PropertyDetailsImages.length > 0 ? (
              <PropertyDetailsGallery
                PropertyImagesArr={PropertyDetailsImages}
                Media360={PropertyDetailsMedia360}
              />
            ) : (
              <Skeleton height={479} />
            )}

            <div className="property-detail__details">
              <div className="property-details__info">
                <div className="property-info__section">
                  <div className="property-details__status-type">
                    <span className="badge">{getTransactionType()}</span>{" "}
                    {getPropertyType()}
                  </div>
                  <h2 className="property-details__title heading-4">
                    {PropertyDetails.title || <Skeleton />}{" "}
                    {PropertyDetails.city || <Skeleton />}
                  </h2>
                  <h3 className="property-details__price heading-4">
                    <ListingPrice
                      transaction_type={
                        PropertyDetails.listing.transaction_type
                      }
                      sale_price={PropertyDetails.listing.sale_price}
                      lease_price={PropertyDetails.listing.monthly_lease_price}
                    />
                  </h3>
                </div>
                <div className="property-info__section">
                  <div className="property-info_structure">
                    <ul>
                      {PropertyDetails.bedroom_count ? (
                        <li className="no-bedroom">
                          <PropertyStructureIcon />
                          {PropertyDetails.bedroom_count}
                        </li>
                      ) : null}

                      {PropertyDetails.parking_space_count ? (
                        <li className="no-garage">
                          <GarageIcon />
                          {PropertyDetails.parking_space_count}
                        </li>
                      ) : null}

                      {PropertyDetails.lot_area && (
                        <li className="lot-area">
                          <LotAreaIcon />
                          {formattedLotArea} sqm
                        </li>
                      )}
                      {PropertyDetails.floor_area && (
                        <li className="floor-area">
                          <FloorAreaIcon />
                          {PropertyDetails.floor_area} sqm
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <PropertyDetailsFeatures
                  collections={PropertyDetails.collections || []}
                />
                <PropertyDetailsDesc
                  description={PropertyDetails.description || []}
                />
                <PropertyDetailsAmenities
                  amenities={PropertyDetails.features}
                />
                <div ref={targetRef}>
                  <PropertyDetailsMap
                    PropertyTitle={PropertyDetails.title}
                    PropertyAddressLine1={PropertyDetails.address_line_1}
                    PropertyAddressLine2={PropertyDetails.address_line_2}
                    PropertyCity={PropertyDetails.city}
                    PropertyState={PropertyDetails.state}
                    PropertyCountry={PropertyDetails.country}
                    PropertyLat={PropertyDetails.latitude}
                    PropertyLong={PropertyDetails.longitude}
                    PropertyLocation={PropertyDetails.location?.coordinates}
                  />
                </div>
                <LocationScore scores={PropertyDetailsLocationScore} />
              </div>
              <SlideInRight className={isContactActive ? "active" : ""}>
                <PropertyDetailsContact
                  PropertyName={PropertyDetails.title}
                  onContactClick={() => setIsContactActive((prev) => !prev)}
                />
              </SlideInRight>
            </div>
          </div>
        </PropertyDetailsstyle>
        {/* <SuggestedPropertiesstyle>
          <div className="suggested-properties">
            <div className="container">
              <h2 className="heading-5">
                Explore other properties in {PropertyDetails.city}{" "}
              </h2>
            </div>
            <SuggestedPropertiesGrid />
          </div>
        </SuggestedPropertiesstyle> */}
      </SkeletonTheme>
      <PropertyDetailsMobileBottomNav
        transaction_type={PropertyDetails.listing.transaction_type}
        sale_price={PropertyDetails.listing.sale_price}
        lease_price={PropertyDetails.listing.monthly_lease_price}
        onContactClick={() => setIsContactActive((prev) => !prev)}
      />
    </>
  );
};
export default PropertyDetails;
