import React from "react";
import styled from "styled-components";
import SiteLogo from "../Assets/logo-dark.svg";
import { Link } from "react-router-dom";
import {
  openFormPopupContactUs,
  openFormPopupListWithUs,
} from "./Util/PopupForms";

const Footerstyle = styled.footer`
  background-color: var(--color-dark);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  color: #fff;
  font-family: var(--font-secondary);
  padding: 32px 0;

  @media screen and (min-width: 768px) {
    padding: 100px 0 50px;
  }

  .footer-sections {
    display: block;
    margin-top: 24px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }

    .footer-section {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      margin: 32px 0;

      &:last-child {
        border: none;
      }

      @media screen and (min-width: 768px) {
        border-bottom: none;
      }

      h2 {
        font-size: 24px;
        font-weight: normal;
      }

      @media screen and (min-width: 768px) {
        width: 33%;
      }

      ul {
        display: block;

        li {
          margin-bottom: 24px;

          @media screen and (min-width: 768px) {
            margin-bottom: 0;
          }
        }

        @media screen and (min-width: 768px) {
          display: grid;
          grid-gap: 24px;
        }
      }

      a {
        color: #fff;
        transition: all linear 200ms;

        &:hover {
          color: var(--color-primary);
        }
      }
    }
    .section-getintouch {
      li,
      a {
        display: flex;
        align-items: center;

        svg {
          margin-right: 16px;
        }
      }
    }
    .section-navigation {
      ul {
        @media (min-width: 992px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }

  .footer-copyright {
    margin-top: 50px;
  }
`;

const Footer = () => {
  return (
    <Footerstyle>
      <div className="container">
        <div className="logo">
          <img src={SiteLogo} alt="Propeller PH" />
        </div>
        <div className="footer-sections">
          <div className="footer-section section-navigation">
            <h2>Navigation</h2>
            <ul className="menu-nav">
              <li>
                <a href="/?q=1&transactionType=sale">Buy</a>
              </li>
              <li>
                <a href="/?q=1&transactionType=lease">Lease</a>
              </li>
              <li>
                <Link to={"/services"}>Services</Link>
              </li>
              <li>
                <a href="https://forms.gle/jyAU78o9zsnvALdE7" target={"_blank"}>
                  List with us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section section-about">
            <h2>About Propeller</h2>
            <ul>
              <li>
                <Link to={"/about-us"}>About</Link>
              </li>
              <li>
                <a
                  href="https://forms.gle/eDaKmbJ9B1yKoQPu7"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section section-getintouch">
            <h2>Get in touch</h2>
            <ul>
              <li>
                <a href="mailto:propeller.realty@gmail.com">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4225 5.80768L10 9.7889L3.57746 5.80768V4.19266L10 8.21144L16.4225 4.19266V5.80768ZM16.4225 2.57764H3.57746C3.12676 2.57764 2.75117 2.76543 2.4507 3.0659C2.15023 3.40393 2 3.77951 2 4.19266V13.8077C2 14.2584 2.15023 14.634 2.4507 14.9344C2.75117 15.2725 3.12676 15.4227 3.57746 15.4227H16.4225C16.8357 15.4227 17.2113 15.2725 17.5117 14.9344C17.8122 14.634 18 14.2584 18 13.8077V4.19266C18 3.77951 17.8122 3.40393 17.5117 3.0659C17.2113 2.76543 16.8357 2.57764 16.4225 2.57764Z"
                      fill="white"
                    />
                  </svg>
                  propeller.realty@gmail.com
                </a>{" "}
              </li>
              <li>
                <a href="tel:+639060012922">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.20833 8.91667L7.16667 6.95833C7.41667 6.70833 7.45833 6.41667 7.375 6.04167C7.04167 5.04167 6.875 4 6.875 2.875C6.875 2.625 6.79167 2.41667 6.625 2.25C6.45833 2.08333 6.25 2 6 2H2.875C2.625 2 2.41667 2.08333 2.25 2.25C2.08333 2.41667 2 2.625 2 2.875C2 7.04167 3.45833 10.625 6.41667 13.5833C9.375 16.5417 12.9583 18 17.125 18C17.375 18 17.5833 17.9167 17.75 17.75C17.9167 17.5833 18 17.375 18 17.125V14C18 13.75 17.9167 13.5417 17.75 13.375C17.5833 13.2083 17.375 13.125 17.125 13.125C16 13.125 14.9583 12.9583 13.9583 12.625C13.625 12.5 13.2917 12.5833 13.0417 12.8333L11.0833 14.7917C8.5 13.4583 6.54167 11.5 5.20833 8.91667Z"
                      fill="white"
                    />
                  </svg>
                  +63906-0012922
                </a>
              </li>
              <li>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00005 9.58685C7.43667 9.58685 6.94841 9.39906 6.57282 9.02347C6.19724 8.64789 6.00944 8.15962 6.00944 7.59624C6.00944 7.07042 6.19724 6.58216 6.57282 6.20657C6.94841 5.83099 7.43667 5.60563 8.00005 5.60563C8.52587 5.60563 9.01414 5.83099 9.38972 6.20657C9.76531 6.58216 9.99066 7.07042 9.99066 7.59624C9.99066 8.15962 9.76531 8.64789 9.38972 9.02347C9.01414 9.39906 8.52587 9.58685 8.00005 9.58685ZM8.00005 2C6.42259 2 5.10803 2.56338 4.01883 3.65258C2.92963 4.74178 2.40381 6.05634 2.40381 7.59624C2.40381 8.38498 2.5916 9.28639 3.00475 10.3005C3.38033 11.3521 3.83104 12.2535 4.3193 13.0798C4.80756 13.9061 5.37094 14.7324 6.047 15.5962C6.6855 16.4601 7.1362 17.0235 7.39911 17.3239C7.62447 17.6244 7.84982 17.8498 8.00005 18L8.60099 17.3615C8.97658 16.9108 9.42728 16.3099 9.9531 15.5587C10.4789 14.8075 11.0423 13.9812 11.6057 13.0798C12.1315 12.216 12.6198 11.277 12.9954 10.2629C13.3709 9.28639 13.5963 8.38498 13.5963 7.59624C13.5963 6.05634 13.0329 4.74178 11.9437 3.65258C10.8545 2.56338 9.53996 2 8.00005 2Z"
                    fill="white"
                  />
                </svg>
                West Capitol Drive, Kapitolyo, Pasig
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright">
          <span>
            Copyright © {new Date().getFullYear()} | Propeller Realty
            Investments
          </span>
        </div>
      </div>
    </Footerstyle>
  );
};

export default Footer;
