import React, { useState } from "react";
import styled from "styled-components";
import Calendly from "./Calendly";

import { PhoneIcon, CloseIcon } from "../Util/SVGIcons";

const PropertyDetailsContactstyle = styled.aside`
  @media screen and (min-width: 768px) {
    display: block;
  }

  .property-details__contact {
    padding: 0px;
    position: sticky;
    top: 220px;

    @media screen and (min-width: 768px) {
      padding: 0 50px;
    }

    &--section,
    &--section h2 {
      margin-bottom: 16px;
    }

    &--box {
      border: 1px solid var(--background-grey-500);
      border-radius: 8px;
      padding: 0;
      background-color: #fff;

      @media (min-width: 992px) {
        padding: 34px;
      }

      h2 {
        color: var(--color-black-500);
      }

      .contact-phone {
        border: 1px solid var(--background-grey-500);
        padding: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    &--wrapper {
      max-height: 100vh;
      overflow: auto;
      position: relative;
      padding: 32px;

      @media (min-width: 992px) {
        max-height: unset;
        overflow: none;
        padding: 0;
      }
    }
    .btn-contact {
      background-color: var(--color-primary);
      border: none;
      padding: 16px;
      display: block;
      width: 100%;
      border-radius: 100px;
      font-size: 20px;
      font-family: var(--font-secondary);
      margin-top: 16px;
      text-align: center;
      color: #fff;
      transition: all linear 200ms;

      &:hover {
        background-color: var(--color-secondary);
      }
    }
    .section-calendly {
      margin-top: 40px;
    }
    .calendly-inline-widget {
      margin-top: 16px;
      min-height: 780px;
    }
    .contact-phone a {
      color: initial;

      svg {
        position: relative;
        top: 3px;
        margin-right: 16px;
      }
    }
  }
  .close-btn {
    display: block;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9999;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
`;

function openFormPopup(PropertyName, currentUrl) {
  const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLScr_mdaYyQTyupt-RKCRfKZcaXA3G4qPvjc2WcU0ZsO-nTb5w/viewform?entry.1976209359=${
    PropertyName + " " + currentUrl
  }&embedded=true`;
  window.open(formUrl, "formPopup", "width=600,height=800");
}

function PropertyDetailsContact({ PropertyName, onContactClick }) {
  const [value, setValue] = useState(new Date());
  const currentUrl = window.location.href;

  return (
    <PropertyDetailsContactstyle>
      <div className="property-details__contact">
        <div className="property-details__contact--box">
          <div className="close-btn" onClick={onContactClick}>
            <CloseIcon
              fill={"var(--primary-grey-300)"}
              width={24}
              height={24}
            />
          </div>
          <div className="property-details__contact--wrapper">
            <div className="property-details__contact--section">
              <h2 className="heading-5">Contact Propeller Realty</h2>
              <div className="contact-phone">
                <PhoneIcon
                  width={20}
                  height={20}
                  fill={"var(--primary-grey-300)"}
                />
                +63906-0012922
              </div>

              <a
                href="#"
                onClick={() =>
                  openFormPopup(PropertyName, window.location.href)
                }
                className="btn-contact"
              >
                Inquire
              </a>
            </div>
            <div className="property-details__contact--section section-calendly">
              <h2 className="heading-5">Schedule a viewing</h2>
              <h6 className="heading-6">Select a Date & Time</h6>

              <Calendly PropertyName={PropertyName} />
            </div>
          </div>
        </div>
      </div>
    </PropertyDetailsContactstyle>
  );
}

export default PropertyDetailsContact;
