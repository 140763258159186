import React, { useState } from "react";
import styled from "styled-components";
//import SwiperCore, { Controller } from "swiper";
import { Navigation, Thumbs, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//SwiperCore.use([Controller]);

const GalleryModalstyle = styled.section`
  position: fixed;
  overflow: hidden;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 9999;
  background-color: var(--background-grey-90);
  transition: 200ms;
  animation: moveUp 200ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  @keyframes moveUp {
    0% {
      transform: translateY(150px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .modal-gallery__nav {
    position: sticky;
    width: 100%;
    background-color: var(--background-grey-90);
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-gallery-close {
      margin-left: -15px;
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        border-radius: 100px;
        transition: all linear 200ms;

        &:hover {
          background-color: var(--background-grey-200);
        }
      }
    }

    .property-detail__actions {
      margin-top: 0;
    }
  }
  .modal-gallery__inner {
    height: 100%;
    position: relative;
    overflow-y: auto;
    width: 100%;
    margin: 0 0 60px 0;

    .swiper {
    }

    .property-gallery__container {
      padding-bottom: 130px;
    }
  }

  /* picture {
    border-radius: 16px;
    display: block;
    margin: 16px 0;
    overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
  } */
  .swiper-main {
    max-height: 500px;
    border-radius: 6px;
    overflow: hidden;

    @media (min-width: 992px) {
      max-height: 600px;
    }

    .swiper-wrapper,
    .swiper-slide {
      min-height: 650px;
      max-height: 650px;
      height: 100%;
      background-color: #f0f2f5;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        object-position: center;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    .swiper-pagination-bullet {
      background-color: #fff;
      width: 10px;
      height: 10px;
      opacity: 0.5;
    }
  }
  .swiper-thumbs {
    margin-top: 16px;
    //overflow: visible;

    .swiper-wrapper {
      //justify-content: center;
    }

    .swiper-slide {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 6px;
      cursor: grab;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        -webkit-filter: brightness(100%);
        transition: brightness linear 200ms;

        &:hover {
          -webkit-filter: brightness(70%);
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      &:after {
        font-size: 24px;
      }
    }
    .swiper-button-prev {
      left: -30px;
    }
    .swiper-button-next {
      right: -30px;
    }
  }
`;

function PropertyDetailsGalleryModal(props) {
  const PropertyListingImages = props.PropertyImagesArr;

  //   const PropertyListingList = PropertyListingImages.map((number, index) => (
  //     <div className={`property-detail__gallery--column img-${index}`}>
  //       <picture>
  //         <img src={number.file} />
  //       </picture>
  //     </div>
  //   ));

  const [buttonPopup, setButtonPopup] = useState(false);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handlePrint = () => {
    setTimeout(() => {
      document.title = props.title;
      window.print();
    }, 500);
  };

  return props.trigger ? (
    <GalleryModalstyle>
      <div className="container">
        <div className="modal-gallery__nav">
          <div className="modal-gallery-close">
            <button onClick={() => props.setTrigger(false)}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9401 22.125L14.812 16L20.9401 9.875L19.0641 8L11.0601 16L19.0641 24L20.9401 22.125Z"
                  fill="#5E676F"
                />
              </svg>
            </button>
          </div>
          {/* <div className="property-detail__actions">
            <button className="property-detail__icon">
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 3.59727C9.72834 3.29538 9.41164 3.03178 9.06225 2.81191C8.29013 2.32602 7.35834 2.05371 6.4 2.05371C3.936 2.05371 2 3.8858 2 6.21754C2 7.04605 2.20459 7.83281 2.55506 8.58131C3.64208 10.9049 6.13261 12.8658 8.2764 14.5537C8.89359 15.0396 9.48205 15.5029 10 15.9458C10.518 15.5029 11.1064 15.0396 11.7236 14.5537C13.8674 12.8658 16.3578 10.905 17.4449 8.58143C17.7954 7.8329 18 7.0461 18 6.21754C18 3.8858 16.064 2.05371 13.6 2.05371C12.6417 2.05371 11.7099 2.32602 10.9377 2.81191C10.5884 3.03178 10.2717 3.29538 10 3.59727ZM10 13.9159C10.2582 13.7095 10.5222 13.5013 10.7857 13.2934C11.009 13.1173 11.2319 12.9414 11.4509 12.7671C12.3272 12.069 13.1851 11.3599 13.9447 10.6166C14.8848 9.69691 15.5834 8.81167 15.9903 7.93791C16.2586 7.36102 16.4 6.78961 16.4 6.21754C16.4 4.76617 15.2095 3.59727 13.6 3.59727C12.6493 3.59727 11.762 3.99376 11.2082 4.60917L10 5.95185L8.79177 4.60917C8.23799 3.99376 7.35075 3.59727 6.4 3.59727C4.79047 3.59727 3.6 4.76617 3.6 6.21754C3.6 6.78961 3.74139 7.36102 4.00971 7.93791C4.41655 8.81167 5.11522 9.69691 6.05528 10.6166C6.81491 11.3599 7.67276 12.069 8.54914 12.7671C8.76807 12.9414 8.99102 13.1173 9.21426 13.2934C9.47781 13.5013 9.74177 13.7095 10 13.9159Z"
                  fill="#7E6947"
                />
              </svg>
              Save
            </button>
            <button className="property-detail__icon" onClick={handlePrint}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8333 10.7773H13.5948L14.7153 9.65686C15.4469 8.92526 14.9292 7.66623 13.8913 7.66623H12.3358V4.94401C12.3358 4.29991 11.8132 3.77734 11.1691 3.77734H8.83576C8.19167 3.77734 7.6691 4.29991 7.6691 4.94401V7.66623H6.11354C5.07813 7.66623 4.55313 8.92283 5.28958 9.65686L6.41007 10.7773H4.16667C3.52257 10.7773 3 11.2999 3 11.944V15.0551C3 15.6992 3.52257 16.2218 4.16667 16.2218H15.8333C16.4774 16.2218 17 15.6992 17 15.0551V11.944C17 11.2999 16.4774 10.7773 15.8333 10.7773ZM6.11111 8.8329H8.83333V4.94401H11.1667V8.8329H13.8889L10 12.7218L6.11111 8.8329ZM15.8333 15.0551H4.16667V11.944H7.57188L9.17361 13.5457C9.63056 14.0027 10.367 14.0003 10.824 13.5457L12.4257 11.944H15.8333V15.0551ZM13.6944 13.4996C13.6944 13.1763 13.9545 12.9162 14.2778 12.9162C14.601 12.9162 14.8611 13.1763 14.8611 13.4996C14.8611 13.8228 14.601 14.0829 14.2778 14.0829C13.9545 14.0829 13.6944 13.8228 13.6944 13.4996Z"
                  fill="#7E6947"
                />
              </svg>
              Export
            </button>
          </div> */}
        </div>
      </div>
      <div className="modal-gallery__inner">
        <div className="modal-gallery__container container">
          {/* {PropertyListingList} */}
          {props.children}

          <div className="property-gallery__container">
            <Swiper
              autoplay={true}
              className="swiper-main"
              //centeredSlides={true}
              slidesPerView={1}
              //navigation
              autoHeight={true}
              loop={false}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation, Thumbs]}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
            >
              {PropertyListingImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image.file} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={16}
              slidesPerView={2}
              navigation
              watchSlidesVisibility={true}
              watchSlidesProgress={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 5,
                },
                1200: {
                  slidesPerView: 5,
                },
              }}
            >
              {PropertyListingImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image.file} width={150} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </GalleryModalstyle>
  ) : (
    ""
  );
}

export default PropertyDetailsGalleryModal;
