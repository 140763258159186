import React from 'react';
import {
    Circle, MapContainer, TileLayer, useMap
} from 'react-leaflet';
import styled from 'styled-components';

const PropertyMapstyle = styled.section`
    .leaflet-container {
        height: 500px;
        z-index: 0;
    }
`
function MapControl(props) {
    const map = useMap();
    map.setView(props.center, 16);
}

function PropertyDetailsMap(props) {
    const fillBlueOptions = { fillColor: 'blue' };
    let longitude, latitude;

    if (props.PropertyLocation) {
        [longitude, latitude] = props.PropertyLocation;
    } else if (props.PropertyLat && props.PropertyLng) {
        latitude = props.PropertyLat;
        longitude = props.PropertyLng;
    } else {
        return null;
    }
    const position = [latitude, longitude];
    return (
        <PropertyMapstyle>
            <div className="property-info__section">
                <h2 className="property-details__heading heading-5">
                    Location
                </h2>
                <MapContainer center={position} zoom={16} scrollWheelZoom={true}>
                    <MapControl center={position} />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Circle center={position} pathOptions={fillBlueOptions} radius={200} />
                </MapContainer>
            </div>
        </PropertyMapstyle>
    )
}

export default PropertyDetailsMap
