const setFilters = (filters, key, value) => {
  switch (key) {
    case 'price':
      const [min, max] = value.split('-');
      return { ...filters, min_price: min, max_price: max, price: value };
    default:
      return { ...filters, [key]: value };
  }
};

export function FilterReducer(filters, payload) {
  let newFilters = filters;
  switch (payload.action) {
    case 'clear':
      newFilters = null;
      break;
    case 'set':
      const { key, value } = payload.filter
      newFilters = setFilters(filters, key, value)
      break;
    case 'delete':
      const { key: deleteKey } = payload.filter
      newFilters = { ...filters };
      delete newFilters[deleteKey];
      break;
    default:
      console.error('FilterReducer: unknown action', payload.action)
  }
  localStorage.setItem('PropellerPropertyFilters', JSON.stringify(newFilters));
  return newFilters;
}
