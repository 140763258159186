import React, { Component } from 'react';

import { InlineWidget } from 'react-calendly';
class Calendly extends Component {
  componentDidMount() {
    // whatever stuff you need here
  }
  componentWillUnmount() {
    // whatever cleanup stuff you need here
  }
  render(){
    const { PropertyName } = this.props

    const message = `Hi, I would like to inquire about this property.\n\nProperty: ${PropertyName}\n\nLink: ${window.location.href}`
    return (
      <div>
        <InlineWidget
            //url="https://calendly.com/jaymar-panlilio?hide_landing_page_details=1&hide_gdpr_banner=1"
            url={`https://calendly.com/propeller-realty/viewings?a1=${encodeURIComponent(message)}`}

            pageSettings={{
                backgroundColor: '#333',
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '4d5055'
              }}

              prefill={{
                email: 'test@test.com',
                name: 'Jon Snow'

              }}
        />
      </div>
    );
  }
}

export default Calendly
