import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PhoneIcon, SearchIcon } from "../Util/SVGIcons";
import ListingPrice from "../Util/ListingPrice";

const PropertyDetailsMobileBottomNavstyle = styled.div`
  //display: block;
  bottom: 0;
  position: fixed;
  z-index: 99;
  width: 100vw;
  background-color: var(--color-white);
  transition: 200ms;
  //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: var(--elevation-4dp);
  transform: translateY(150px);

  @media only screen and (min-width: 992px) {
    display: none;
  }

  &.slideUp {
    animation: moveUp 200ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  @keyframes moveUp {
    to {
      transform: translateY(0);
    }
  }

  .container {
    height: 100%;
    padding: 16px 0;
  }

  .mobile-bottom-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      border: none;
      color: var(--color-secondary);
      background-color: #ddb367;
      font-size: 20px;
      color: #fff;
      border-radius: 9999px;
      padding: 16px;
      font-weight: 400;
      cursor: pointer;
      transition: all linear 200ms;

      &:hover {
        background-color: var(--color-secondary);
      }

      svg,
      span {
        display: block;
        margin: 0 auto;
      }
    }
    .listing-price {
      color: var(--accent-teal-500);
    }
  }
`;

function PropertyDetailsMobileBottomNav({
  onContactClick,
  transaction_type,
  sale_price,
  lease_price,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      let scrolled = window.scrollY;
      let windowHeight = window.innerHeight;
      let fullHeight = document.documentElement.scrollHeight;
      let scrollableHeight = fullHeight - windowHeight; // total height minus height of the viewport

      // Check if scrolled past 80% of the total scrollable height
      if (scrolled > 0.8 * scrollableHeight) {
        setIsVisible(false);
        return;
      }

      if (scrolled > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", checkScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  // function onContactClick() {
  //   setIsContactActive((prev) => !prev); // toggle the state
  // }

  return isVisible ? (
    <PropertyDetailsMobileBottomNavstyle className="slideUp">
      <div className="container">
        <div className="mobile-bottom-nav">
          <span className="listing-price heading-5">
            <ListingPrice
              transaction_type={transaction_type}
              sale_price={sale_price}
              lease_price={lease_price}
            />
          </span>
          {/* <button>

                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 5.11111C16.0246 4.50264 15.4704 3.97133 14.8589 3.52818C13.5077 2.54886 11.8771 2 10.2 2C5.888 2 2.5 5.69264 2.5 10.3924C2.5 12.0623 2.85804 13.648 3.47136 15.1566C5.37364 19.84 9.73206 23.7922 13.4837 27.1942C14.5638 28.1736 15.5936 29.1075 16.5 30C17.4064 29.1075 18.4362 28.1736 19.5163 27.1942C23.2679 23.7923 27.6262 19.8402 29.5285 15.1569C30.1419 13.6482 30.5 12.0624 30.5 10.3924C30.5 5.69264 27.112 2 22.8 2C21.1229 2 19.4923 2.54886 18.1411 3.52818C17.5296 3.97133 16.9754 4.50264 16.5 5.11111ZM16.5 25.9088C16.9519 25.4926 17.4138 25.073 17.875 24.654C18.2657 24.2991 18.6559 23.9447 19.039 23.5932C20.5727 22.1863 22.0739 20.7569 23.4033 19.2589C25.0484 17.4052 26.271 15.6209 26.983 13.8598C27.4526 12.6971 27.7 11.5454 27.7 10.3924C27.7 7.46707 25.6167 5.11111 22.8 5.11111C21.1362 5.11111 19.5835 5.91024 18.6144 7.15062L16.5 9.85686L14.3856 7.15062C13.4165 5.91024 11.8638 5.11111 10.2 5.11111C7.38332 5.11111 5.3 7.46707 5.3 10.3924C5.3 11.5454 5.54744 12.6971 6.01699 13.8599C6.72897 15.621 7.95163 17.4052 9.59673 19.2589C10.9261 20.7569 12.4273 22.1863 13.961 23.5932C14.3441 23.9447 14.7343 24.2991 15.125 24.654C15.5862 25.073 16.0481 25.4926 16.5 25.9088Z" fill="#939DAC" />
                        </svg>

                        <span>My Items</span>
                    </button> */}
          <button onClick={onContactClick}>
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </PropertyDetailsMobileBottomNavstyle>
  ) : null;
}
export default PropertyDetailsMobileBottomNav;
