import React from "react";
import styled from "styled-components";

const AboutUsstyle = styled.section`
  @media only screen and (min-width: 768px) {
    min-height: 328px;
  }

  section {
    padding: 60px 0;
  }

  p {
    color: var(--text-black-300);
  }
  .heading-2,
  .heading-3 {
    span {
      color: var(--color-secondary-500);
    }
  }
  .about__hero {
    margin-top: 22px;
    padding-bottom: 0;

    .container {
      display: block;
      padding: 60px 0;

      @media only screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
      }

      .hero-text {
        .heading-3 {
          font-size: 38px;
          line-height: 1.3;

          @media only screen and (min-width: 768px) {
            font-size: 32px;
          }
        }
      }
      .hero-img {
        text-align: right;
        margin-top: 36px;

        img {
          max-width: 100%;
        }
      }
      .hero-text,
      .hero-img {
        max-width: 100%;
      }
      @media only screen and (min-width: 768px) {
        .hero-text,
        .hero-img {
          max-width: 50%;
        }
        .hero-img {
          margin-top: 0;
        }
      }

      @media only screen and (min-width: 992px) {
        .hero-text {
          max-width: 500px;
        }
        .hero-img {
          text-align: right;
          max-width: 50%;
        }
      }
    }
  }
  .heading-3 {
    font-size: 36px;

    @media only screen and (min-width: 992px) {
      font-size: 48px;
    }
  }
  .about__intro {
    background-color: var(--background-grey-100);
    p {
      display: block;
      max-width: 870px;
      margin: 24px auto;
    }
  }
  .about__founders {
    background-color: var(--background-grey-100);
  }
  .grid__container {
    margin-top: 32px;
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (min-width: 1440px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    h2 {
      color: var(--color-primary);
      margin-bottom: 0;
    }

    .card__founder {
      * {
        transition: all linear 200ms;
      }

      h3 {
        color: var(--color-black-500);
      }

      img {
        filter: grayscale(100%);
        transition: all linear 300ms;
        margin-bottom: 24px;

        &:hover {
          filter: none;
        }
      }

      &:hover {
        cursor: pointer;

        h3 {
          color: var(--color-primary);
        }
      }
    }
  }
  .about__story {
    .card__story {
      border-radius: 16px;
      border: 1px solid var(--primary-grey-100);
      overflow: hidden;
    }
    .card__image {
      min-height: 266px;
      background-color: #d9d9d9;
      margin-bottom: 24px;
    }
    .card__content {
      padding: 0 16px 16px;

      .heading-6 {
        color: var(--color-secondary-500);
      }
    }
  }

  .smart-icons {
    display: block;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 58px;
    }

    .smart-icon {
      text-align: center;
      width: auto;
      margin-top: 50px;

      @media screen and (min-width: 768px) {
        width: 30%;
      }

      picture {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: var(--color-secondary-500);
        margin-bottom: 24px;
        padding: 16px;

        @media screen and (min-width: 768px) {
          width: 127px;
          height: 127px;
        }
      }
    }
  }
`;

const AboutUs = () => {
  return (
    <React.Fragment>
      <AboutUsstyle>
        <section className="about__hero">
          <div className="container">
            <div className="hero-text">
              <h1 className="heading-3">
                Make Every Property a <br /> <span>Purposeful Legacy</span>
              </h1>
              <p>
                Propeller Realty is a Filipino real estate agency diversifying
                in the sale and lease of residential and commercial properties
                for personal and business use.
              </p>

              <p>
                We aim to be the top Filipino phygital brokerage in Metro Manila
                offering legacy properties for real estate portfolio builders,
                empowering them with innovative solutions and personalized
                services.
              </p>
            </div>
            <div className="hero-img">
              <img src={"/Assets/about/about-us-hero.png"} />
            </div>
          </div>
        </section>
        <section className="about__intro smart-values">
          <div className="container">
            <h1 className="heading-3 text-center">
              Our <span>S.M.A.R.T.</span> Values
            </h1>
            <div className="smart-icons">
              <div className="smart-icon">
                <picture>
                  <svg
                    width="55"
                    height="72"
                    viewBox="0 0 55 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53.6218 23.6109C52.4687 21.6 50.3734 20.3906 48.0531 20.3906H34.7781L38.814 8.08594C39.3343 6.14531 38.9265 4.10625 37.7031 2.50312C36.4656 0.914062 34.5953 0 32.5843 0H14.2328C11.0265 0 8.28434 2.40469 7.93278 5.21719L0.564027 35.0578C0.296839 36.9984 0.873402 38.9531 2.15309 40.4297C3.43278 41.9062 5.28903 42.75 7.24371 42.75H21.039L16.1312 64.0828C15.6812 66.0094 16.1172 68.0062 17.3406 69.5531C18.564 71.1141 20.4062 72 22.389 72C24.6812 72 26.8187 70.7625 27.8453 68.9625L53.6078 30.0516C54.789 28.0406 54.789 25.6219 53.6218 23.6109ZM23.0078 64.3219L29.5187 36H7.1734L14.2328 6.75L32.1765 6.62344L25.4547 27.1406H47.4625L23.0078 64.3219Z"
                      fill="white"
                    />
                  </svg>
                </picture>
                <h3 className="heading-4">Swift</h3>
                <p>Respond to Client's needs in a quick and efficient manner</p>
              </div>

              <div className="smart-icon">
                <picture>
                  <svg
                    width="63"
                    height="72"
                    viewBox="0 0 63 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M63 54V2.25C63 1.0125 61.9875 0 60.75 0H11.25C5.03437 0 0 5.03437 0 11.25V60.75C0 66.9656 5.03437 72 11.25 72H60.75C61.9875 72 63 70.9875 63 69.75V67.5C63 66.4031 62.2125 65.4891 61.1859 65.2922C60.5953 63.4641 60.5953 58.0359 61.1859 56.2078C62.2266 55.9969 63 55.0969 63 54ZM55.4062 65.25H11.25C8.76094 65.25 6.75 63.2391 6.75 60.75C6.75 58.275 8.775 56.25 11.25 56.25H55.4062C55.0266 58.6828 55.0266 62.8172 55.4062 65.25ZM56.25 49.5H11.25C9.64688 49.5 8.12813 49.8375 6.75 50.4422V11.25C6.75 8.76094 8.76094 6.75 11.25 6.75H56.25V49.5ZM34.5009 37.5005L38.25 45L41.9991 37.5005L49.5 33.75L41.9991 29.9995L38.25 22.5L34.5009 29.9995L27 33.75L34.5009 37.5005ZM22.5 28.125L25.1241 22.8755L30.375 20.25L25.1241 17.6245L22.5 12.375L19.8745 17.6245L14.625 20.25L19.8745 22.8755L22.5 28.125Z"
                      fill="white"
                    />
                  </svg>
                </picture>
                <h3 className="heading-4">Meticulous</h3>
                <p>
                  Make sure everything up to the tiniest contract detail is
                  reviewed more than twice and is delivered neatly
                </p>
              </div>

              <div className="smart-icon">
                <picture>
                  <svg
                    width="72"
                    height="70"
                    viewBox="0 0 72 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M68.4 62.4H67.2V54C67.2 52.0117 65.5882 50.4 63.6 50.4H61.2V27.6H54V50.4H46.8V27.6H39.6V50.4H32.4V27.6H25.2V50.4H18V27.6H10.8V50.4H8.4C6.41175 50.4 4.8 52.0117 4.8 54V62.4H3.6C1.61175 62.4 5.69771e-09 64.0117 5.69771e-09 66V68.4C5.69771e-09 68.7183 0.126428 69.0235 0.351472 69.2485C0.576515 69.4736 0.88174 69.6 1.2 69.6H70.8C71.1183 69.6 71.4235 69.4736 71.6485 69.2485C71.8736 69.0235 72 68.7183 72 68.4V66C72 64.0117 70.3883 62.4 68.4 62.4ZM60 62.4H12V57.6H60V62.4ZM70.84 13.9587L38.2768 0.369451C36.7989 -0.12315 35.2011 -0.12315 33.7232 0.369451L1.15995 13.9587C0.818831 14.0885 0.525219 14.3188 0.318056 14.6193C0.110894 14.9198 -2.90269e-05 15.2761 5.69771e-09 15.6411V19.8C5.69771e-09 20.7941 0.80595 21.6 1.8 21.6H4.8V23.4C4.8 24.3941 5.60595 25.2 6.6 25.2H65.4C66.3941 25.2 67.2 24.3941 67.2 23.4V21.6H70.2C71.1941 21.6 72 20.7941 72 19.8V15.6411C72 14.8938 71.5385 14.2243 70.84 13.9587ZM7.2 18L36 7.2L64.8 18H7.2Z"
                      fill="white"
                    />
                  </svg>
                </picture>
                <h3 className="heading-4">Authoritative</h3>
                <p>
                  Give expert advice backed by facts, education and professional
                  consultants
                </p>
              </div>

              <div className="smart-icon">
                <picture>
                  <svg
                    width="73"
                    height="58"
                    viewBox="0 0 73 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M72.722 22.9487L71.8221 21.3851C71.3271 20.519 70.2248 20.2265 69.3586 20.7215L58.4811 27.0657C58.0649 23.9836 55.4552 21.5989 52.2606 21.5989H35.1626V28.798C35.1626 30.7778 33.5541 32.3976 31.563 32.3976C29.572 32.3976 27.9635 30.7778 27.9635 28.798V19.0904C27.9635 17.853 28.6046 16.6944 29.6508 16.042L33.4078 13.691C34.5552 12.9711 35.8713 12.5999 37.2211 12.5999H49.066L62.5419 4.92831C63.4081 4.43336 63.7118 3.34224 63.2168 2.47609L62.3282 0.912525C61.8332 0.0463763 60.7421 -0.257338 59.876 0.237604L47.6487 7.21179H37.2211C34.8589 7.21179 32.5529 7.87546 30.5507 9.12406L26.7823 11.475C24.7238 12.7574 23.3402 14.7822 22.8228 17.0769L16.1411 21.0814C13.4302 22.7125 11.7653 25.6484 11.7653 28.8205V32.6563L1.8665 38.3706C1.00036 38.8656 0.70789 39.968 1.20283 40.8341L2.10273 42.3977C2.59767 43.2638 3.70004 43.5563 4.56619 43.0613L17.1647 35.7834V28.8318C17.1647 27.5607 17.8284 26.3795 18.9195 25.7271L22.5641 23.5224V28.2693C22.5641 32.9713 25.9387 37.2795 30.6294 37.752C36.0175 38.3032 40.562 34.0736 40.562 28.798V26.9982H52.2606C52.7556 26.9982 53.1605 27.4032 53.1605 27.8981V31.4977C53.1605 31.9926 52.7556 32.3976 52.2606 32.3976H48.661V39.1468C48.661 40.8791 47.255 42.2739 45.5339 42.2739H42.823V44.9736C42.823 46.9759 41.2032 48.5957 39.2009 48.5957H24.7351L17.6709 52.679C16.8048 53.1739 16.5123 54.2763 17.0072 55.1424L17.9071 56.706C18.4021 57.5721 19.5044 57.8646 20.3706 57.3697L26.1749 54.0063H39.2009C43.3517 54.0063 46.8612 51.1829 47.9074 47.3471C51.4507 46.3122 54.0604 43.0276 54.0604 39.1581V37.5495C56.0402 36.9533 57.6037 35.4122 58.2337 33.455L72.0583 25.4122C72.9244 24.906 73.2169 23.8148 72.722 22.9487Z"
                      fill="white"
                    />
                  </svg>
                </picture>
                <h3 className="heading-4">Respectful/Responsible</h3>
                <p>
                  Meet client’ and partners’ demands in a professional and
                  pleasant manner that will make them keep coming back to us for
                  Repeat Business
                </p>
              </div>

              <div className="smart-icon">
                <picture>
                  <svg
                    width="68"
                    height="73"
                    viewBox="0 0 68 73"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.702 32.4387C20.041 31.7777 18.9722 31.7777 18.3112 32.4246L15.1187 35.589C14.4577 36.25 14.4577 37.3188 15.1046 37.9798L27.8746 50.8483C28.5356 51.5093 29.6045 51.5093 30.2655 50.8624L54.5397 26.785C55.2007 26.124 55.2007 25.0552 54.5538 24.3941L51.3894 21.2016C50.7284 20.5406 49.6595 20.5406 48.9985 21.1876L29.1122 40.9051L20.702 32.4387ZM63.3577 11.7929L36.3551 0.54182C35.5326 0.200601 34.6508 0.0249634 33.7603 0.0249634C32.8698 0.0249634 31.9881 0.200601 31.1655 0.54182L4.16291 11.7929C1.64547 12.8336 0 15.2948 0 18.0232C0 45.94 16.1031 65.2356 31.1515 71.5081C32.811 72.1973 34.6815 72.1973 36.341 71.5081C48.3938 66.4873 67.5066 49.1466 67.5066 18.0232C67.5066 15.2948 65.8611 12.8336 63.3577 11.7929ZM33.7533 65.2778C20.041 59.5679 6.75066 41.8333 6.75066 18.0232L33.7533 6.77212L60.7559 18.0232C60.7559 42.4662 46.9171 59.7929 33.7533 65.2778Z"
                      fill="white"
                    />
                  </svg>
                </picture>
                <h3 className="heading-4">Trustworthy</h3>
                <p>Exhibit integrity and transparency in everything we do</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="about__grid about__founders">
          <div className="container">
            <h2 className="heading-3">Founders</h2>
            <div className="grid__container">
              <div className="card__founder">
                <img src={"/Assets/about/founder.png"} />
                <h3 className="heading-6">Gordon Norman</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et neque non tellus sagittis luctus.
                </p>
              </div>
              <div className="card__founder">
                <img src={"/Assets/about/founder.png"} />
                <h3 className="heading-6">Gordon Norman</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et neque non tellus sagittis luctus.
                </p>
              </div>
              <div className="card__founder">
                <img src={"/Assets/about/founder.png"} />
                <h3 className="heading-6">Gordon Norman</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et neque non tellus sagittis luctus.
                </p>
              </div>
              <div className="card__founder">
                <img src={"/Assets/about/founder.png"} />
                <h3 className="heading-6">Gordon Norman</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et neque non tellus sagittis luctus.
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about__grid about__story">
          <div className="container">
            <h2 className="heading-3">Blog</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
              neque non tellus sagittis luctus.
            </p>
            <div className="grid__container">
              <div className="card__story">
                <div className="card__image"></div>
                <div className="card__content">
                  <h3 className="heading-6">Early days</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    et neque non tellus sagittis luctus.
                  </p>
                </div>
              </div>

              <div className="card__story">
                <div className="card__image"></div>
                <div className="card__content">
                  <h3 className="heading-6">Expansion</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    et neque non tellus sagittis luctus.
                  </p>
                </div>
              </div>

              <div className="card__story">
                <div className="card__image"></div>
                <div className="card__content">
                  <h3 className="heading-6">Propeller Platform</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    et neque non tellus sagittis luctus.
                  </p>
                </div>
              </div>
              <div className="card__story">
                <div className="card__image"></div>
                <div className="card__content">
                  <h3 className="heading-6">Launch</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    et neque non tellus sagittis luctus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </AboutUsstyle>
    </React.Fragment>
  );
};

export default AboutUs;
