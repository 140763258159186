import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { PhotosIcon, VideoIcon, View360Icon } from "../Util/SVGIcons";

import PropertyDetailsGalleryModal from "./PropertyDetails.GalleryModal";
import { Swiper, SwiperSlide } from "swiper/react";

import Resizer from "react-image-file-resizer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { FreeMode, Pagination, Navigation } from "swiper";

const PropertyDetailGallerystyle = styled.section`
  .property-detail__gallery {
    position: relative;

    .property-gallery__nav {
      position: absolute;
      bottom: 20px;
      z-index: 3;
      right: 24px;

      button {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid var(--color-secondary-500);
        border-radius: 100px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        color: var(--color-secondary-500);
        font-size: 16px;
        margin: 8px 0;
        min-width: 160px;
        min-height: 42px;
        transition: all linear 200ms;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }
    }

    .property-gallery__container {
      border-radius: 16px;
      min-height: 474px;
      max-height: 474px;
      overflow: hidden;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      //grid-template-rows: 1fr 1fr;
      grid-template-rows: 50% 50%;
      gap: 16px 16px;
      grid-template-areas:
        "img-1 img-2 img-4"
        "img-1 img-3 img-5";

      .img-0 {
        grid-area: img-1;
      }
      .img-1 {
        grid-area: img-2;
      }
      .img-2 {
        grid-area: img-3;
      }
      .img-3 {
        grid-area: img-4;
      }
      .img-4 {
        grid-area: img-5;
      }

      .property-detail__gallery--column {
        picture {
          position: relative;
          cursor: pointer;

          img {
            object-fit: cover;
            vertical-align: top;
            max-width: 100%;
            width: 100%;
            top: 0;

            height: 100%;
          }
          &:not(:nth-last-of-type(1)) {
            img {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
    .img-count-1 {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 16px;
      grid-template-areas:
        "img-1"
        "img-1";
    }
    .img-count-2 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 16px;

      grid-template-areas:
        "img-1 img-2"
        "img-1 img-2";
    }
    .img-count-3 {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 16px;
      grid-template-areas:
        "img-1 img-2 img-3"
        "img-1 img-2 img-3";
    }
    .img-count-4 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50% 50%;
      gap: 16px;
      grid-template-areas:
        "img-1 img-2"
        "img-3 img-4";

      .img-0 {
        grid-area: img-1;
      }
      .img-1 {
        grid-area: img-2;
      }
      .img-2 {
        grid-area: img-3;
      }
      .img-3 {
        grid-area: img-4;
      }
    }
    .last-item {
      img {
        filter: brightness(0.6);
      }
    }
  }
  .gallery-desktop {
    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }
  .gallery-mobile {
    display: block;

    @media only screen and (min-width: 768px) {
      display: none;
    }
    .property-gallery__container {
      display: block;
      min-height: auto;
      max-height: 100%;

      .swiper-slide {
        height: 300px;
        width: auto;
        display: flex;
        align-items: center;
        background-color: #f0f2f5;

        //picture,
        img {
          max-width: 100%;
        }

        picture {
          /* position: absolute;
          top: 0;
          left: 0;
          //height: 100%;
          width: 100%;
          height: 300px; */
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
`;

// const fetchImageAsBlob = async (url) => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   return blob;
// };

// const resizeImageBlob = async (blob) => {
//   return new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       blob,
//       300,
//       300,
//       "JPEG",
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "base64"
//     );
//   });
// };

function PropertyDetailsGallery(props) {
  const PropertyListingImages = props.PropertyImagesArr;
  const PropertyListingImagesCount = props.PropertyImagesArr.length;
  const [isLoaded, setIsLoaded] = useState(false);

  const [resizedImagesMobile, setResizedImagesMobile] = useState([]);

  let imgCount;

  switch (PropertyListingImagesCount) {
    case 1:
      imgCount = "img-count-1";
      break;
    case 2:
      imgCount = "img-count-2";
      break;
    case 3:
      imgCount = "img-count-3";
      break;
    case 4:
      imgCount = "img-count-4";
      break;

    default:
      imgCount = "img-count-5";
  }
  // useEffect(() => {
  //   const resizeImages = async () => {
  //     const resized = await Promise.all(
  //       PropertyListingImages.map(async (img) => {
  //         const blob = await fetchImageAsBlob(img.file);
  //         const dataUri = await resizeImageBlob(blob);
  //         return { ...img, file: dataUri };
  //       })
  //     );
  //     setResizedImagesMobile(resized);
  //   };
  //   resizeImages();
  // }, [PropertyListingImages]);

  const PropertyListingList = PropertyListingImages.slice(0, 5).map(
    (number, index, array) => (
      <div
        className={`property-detail__gallery--column img-${index}${
          index === array.length - 1 ? " last-item" : ""
        }`}
      >
        <picture onClick={() => setButtonPopup(true)}>
          {number.file ? (
            <img
              src={number.file}
              srcSet={`${number.file} 300w`}
              sizes="
              (max-width: 600px) 300px,
              (max-width: 900px) 700px,
              900px
            "
            />
          ) : (
            <Skeleton height={300} width={300} />
          )}
        </picture>
      </div>
    )
  );

  const PropertyListingListMobile = PropertyListingImages.map(
    (number, index) => (
      <SwiperSlide key={index}>
        <picture>
          <img src={number.file} />
        </picture>
      </SwiperSlide>
    )
  );

  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("modal-open", buttonPopup);
  }, [buttonPopup]);

  return (
    <PropertyDetailGallerystyle>
      <div className="property-detail__gallery gallery-desktop">
        <div className="property-gallery__nav">
          {props.MediaVideo && (
            <button>
              <VideoIcon />
              Watch Video
            </button>
          )}
          {props.Media360 && (
            <a href={props.Media360} target="_blank" rel="noopener noreferrer">
              <button>
                <View360Icon />
                360 Tour
              </button>
            </a>
          )}
          <button onClick={() => setButtonPopup(true)}>
            <PhotosIcon />
            All Photos {PropertyListingImagesCount}
          </button>
        </div>
        <div className={`property-gallery__container ${imgCount}`}>
          {PropertyListingList}
        </div>
      </div>
      <div className="property-detail__gallery gallery-mobile">
        <div className="property-gallery__container">
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={28}
            //navigation
            loop={true}
            height={300}
          >
            {PropertyListingListMobile}
          </Swiper>
        </div>
        <div className="property-gallery__nav">
          {props.MediaVideo && (
            <button>
              <VideoIcon />
              Watch Video
            </button>
          )}
          {props.Media360 && (
            <a href={props.Media360} target="_blank" rel="noopener noreferrer">
              <button>
                <View360Icon />
                360 Tour
              </button>
            </a>
          )}
          <button onClick={() => setButtonPopup(true)}>
            <PhotosIcon />
            All Photos {PropertyListingImagesCount}
          </button>
        </div>
      </div>
      <PropertyDetailsGalleryModal
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
        PropertyImagesArr={PropertyListingImages}
      ></PropertyDetailsGalleryModal>
    </PropertyDetailGallerystyle>
  );
}

export default PropertyDetailsGallery;
